import { CoacheeOnboardingDisplayScreen, LegacyCoacheeOnboardingStep } from '../../model/model';
import { CoacheeOnboardingState } from '../types';
import * as Types from './types';

export type CoacheeOnboardingAction = {
  type: string;
  payload: any;
};

const initialState = {
  _id: null,
  error: null,
  loading: false,
  displayStep: CoacheeOnboardingDisplayScreen.FIRST_LOGIN,
  completedSteps: [],
  assignedSteps: [],
  nonDisplayableSteps: [LegacyCoacheeOnboardingStep.MATCHING_SUPPORT],
  showStrengthReportScreen: false,
  onboardingFetched: false
};

const getNextDisplayScreen = (
  assignedSteps: LegacyCoacheeOnboardingStep[],
  completedSteps: LegacyCoacheeOnboardingStep[],
  nonDisplayableSteps: LegacyCoacheeOnboardingStep[]
) => {
  const nextAssignedStep = assignedSteps
    .filter(
      assignedStep =>
        !completedSteps.includes(assignedStep) && !nonDisplayableSteps.includes(assignedStep)
    )
    .shift();

  return (
    nextAssignedStep &&
    CoacheeOnboardingDisplayScreen[
      LegacyCoacheeOnboardingStep[nextAssignedStep] as keyof typeof CoacheeOnboardingDisplayScreen
    ]
  );
};

export const coacheeOnboardingReducer = (
  state: CoacheeOnboardingState = initialState,
  action: CoacheeOnboardingAction
) => {
  switch (action.type) {
    case Types.COACHEE_ONBOARDING_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case Types.COACHEE_ONBOARDING_LOADING:
      return {
        ...state,
        loading: true
      };
    case Types.COACHEE_ONBOARDING_TOGGLE_STRENGTH_REPORT_SCREEN:
      return {
        ...state,
        showStrengthReportScreen: action.payload
      };
    case Types.COACHEE_ONBOARDING_DISPLAY_NEXT_SCREEN:
      return {
        ...state,
        displayStep:
          action.payload ??
          getNextDisplayScreen(state.assignedSteps, state.completedSteps, state.nonDisplayableSteps)
      };
    case Types.COACHEE_ONBOARDING_GET:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null
      };
    case Types.COACHEE_ONBOARDING_UPDATE_COMPLETED_STEP:
      return {
        ...state,
        completedSteps: [...state.completedSteps, action.payload.step],
        loading: false,
        error: null
      };
    case Types.COACHEE_ONBOARDING_SKIP_STEP:
      return {
        ...state,
        nonDisplayableSteps: [...state.nonDisplayableSteps, action.payload]
      };
    default:
      return state;
  }
};
