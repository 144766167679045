import * as Types from './types';

const initialState = {
  loading: false,
  bookmarkLists: null,
  bookmarkListDetail: null,
  bookmarks: [],
  lastAddedBookmarkList: null,
  error: null
};

const mapBookmarkLists = list => {
  return {
    id: list.id,
    title: list.name,
    description: list.description,
    createdBy: list.createdBy,
    createdAt: list.createdAt,
    lastUpdated: list.updatedAt,
    activities: list.bookmarks ?? []
  };
};

export const bookmarksReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.BOOKMARK_LISTS_GET: {
      return {
        ...state,
        loading: false,
        bookmarkLists: action.payload.map(mapBookmarkLists),
        error: null
      };
    }
    case Types.BOOKMARK_LIST_GET_SINGLE: {
      return {
        ...state,
        loading: false,
        bookmarkListDetail: mapBookmarkLists(action.payload),
        error: null
      };
    }
    case Types.BOOKMARK_LISTS_POST: {
      return {
        ...state,
        loading: false,
        bookmarkLists: [...state.bookmarkLists, action.payload].map(mapBookmarkLists),
        lastAddedBookmarkList: action.payload,
        error: null
      };
    }
    case Types.BOOKMARK_LISTS_UPDATE_SINGLE: {
      return {
        ...state,
        loading: false,
        bookmarkLists: [...state.bookmarkLists, action.payload].map(mapBookmarkLists),
        error: null
      };
    }
    case Types.BOOKMARK_LISTS_DELETE_SINGLE: {
      return {
        ...state,
        loading: false,
        bookmarkLists: state.bookmarkLists.filter(
          bookmarkLists => bookmarkLists.bookmarkListsId !== action.payload
        ),
        error: null
      };
    }
    case Types.BOOKMARKS_GET_SINGLE: {
      return {
        ...state,
        loading: false,
        bookmarks: action.payload,
        error: null
      };
    }
    case Types.BOOKMARKS_POST: {
      return {
        ...state,
        loading: false,
        bookmarks: action.payload,
        error: null
      };
    }
    case Types.BOOKMARKS_DELETE_SINGLE: {
      return {
        ...state,
        loading: false,
        bookmarks: state.bookmarks.filter(bookmarks => bookmarks.bookmarksId !== action.payload),
        error: null
      };
    }
    case Types.BOOKMARKS_CLEAR_ALL_LISTS: {
      return {
        ...state,
        loading: false,
        bookmarkLists: null,
        error: null
      };
    }
    case Types.BOOKMARKS_CLEAR_LAST_ADDED_BOOKMARK_LIST: {
      return {
        ...state,
        loading: false,
        lastAddedBookmarkList: null,
        error: null
      };
    }
    case Types.BOOKMARKS_CLEAR_SINGLE: {
      return {
        ...state,
        loading: false,
        bookmarkListDetail: null,
        error: null
      };
    }

    case Types.BOOKMARKS_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case Types.BOOKMARKS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    default:
      return state;
  }
};
