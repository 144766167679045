import { Persona } from '../components/common/zendesk/getHelpCenterUrl';
import {
  Category,
  CoacheeOnboardingDisplayScreen,
  LegacyCoacheeOnboardingStep,
  CoachOnboardingStep,
  CoachProposal,
  FullName,
  LearningActivity,
  MatchingAnswer,
  UserRole,
  CoachSessionAvailability
} from '../model/model';

export enum AlertVariant {
  Error = 'Error',
  Warning = 'Warning',
  Success = 'Success',
  Hint = 'Hint',
  Information = 'Information'
}

export enum AlertButtonVariant {
  Primary = 'Primary',
  Secondary = 'Secondary'
}

export enum ProgramType {
  Collective = 'COLLECTIVE',
  Individual = 'INDIVIDUAL',
  StandaloneAI = 'STANDALONE_AI'
}

export enum ProgramSubType {
  COACHING_HUBS = 'COACHING_HUBS',
  CO_DEVELOPMENT_HUBS = 'CO_DEVELOPMENT_HUBS',
  TRIPARTITE = 'TRIPARTITE'
}

export enum I18nKeysName {
  CHANGE_MANAGEMENT = 'CHANGE_MANAGEMENT',
  COACHING_HUBS = 'COACHING_HUBS',
  COLLECTIVE = 'COLLECTIVE',
  CO_DEVELOPMENT_HUBS = 'CO_DEVELOPMENT_HUBS',
  DE_I = 'DE_I',
  EXECUTIVE = 'EXECUTIVE',
  INDIVIDUAL = 'INDIVIDUAL',
  PRO_BONO = 'PRO_BONO',
  STANDARD = 'STANDARD',
  TRIPARTITE = 'TRIPARTITE',
  TRY_COACHHUB = 'TRY_COACHHUB',
  WELLBEING = 'WELLBEING'
}

export const ProgramDetailsI18nKeys: Record<I18nKeysName, string> = {
  [I18nKeysName.CHANGE_MANAGEMENT]:
    'coach.coacheeProgramDetail.coachingClassification.changeMangagement',
  [I18nKeysName.COACHING_HUBS]: 'coach.coacheeProgramDetail.programSubType.coachingHubs',
  [I18nKeysName.COLLECTIVE]: 'coach.coacheeProgramDetail.programType.collective',
  [I18nKeysName.CO_DEVELOPMENT_HUBS]: 'coach.coacheeProgramDetail.programSubType.coDevelopmentHubs',
  [I18nKeysName.DE_I]: 'coach.coacheeProgramDetail.coachingClassification.diversityAndInclusion',
  [I18nKeysName.EXECUTIVE]: 'coach.coacheeProgramDetail.coachingClassification.executive',
  [I18nKeysName.INDIVIDUAL]: 'coach.coacheeProgramDetail.programType.individual',
  [I18nKeysName.PRO_BONO]: 'coach.coacheeProgramDetail.coachingClassification.proBono',
  [I18nKeysName.STANDARD]: 'coach.coacheeProgramDetail.coachingClassification.standard',
  [I18nKeysName.TRIPARTITE]: 'coach.coacheeProgramDetail.programSubType.tripartite',
  [I18nKeysName.TRY_COACHHUB]: 'coach.coacheeProgramDetail.coachingClassification.tryCoachhub',
  [I18nKeysName.WELLBEING]: 'coach.coacheeProgramDetail.coachingClassification.wellbeing'
};
export enum ProgramAssessmentType {
  THREE_SIXTY = 'THREE_SIXTY',
  ONE_EIGHTY = 'ONE_EIGHTY',
  SELF_ASSESSMENT = 'SELF_ASSESSMENT',
  LEGACY = 'LEGACY',
  NONE = 'NONE'
}

export interface AlertData {
  message?: string;
  title: string;
  variant?: AlertVariant;
  buttonVariant?: AlertButtonVariant;
  action?: () => void;
  buttonLabel?: string;
}

export interface AlertHandlingState {
  isOpen: boolean;
  data?: AlertData;
}

export type RecurringAvailabilities = {
  day: string;
  disabled?: boolean;
  endsAt: string;
  startsAt: string;
};

export type SingleAvailabilities = {
  endsAt: string;
  id: string;
  startsAt: string;
  timezone: string;
};

export interface AvailabilitiesState {
  error: boolean;
  loading: boolean;
  onetime: SingleAvailabilities[];
  recurring: RecurringAvailabilities[];
}

export interface Program {
  name: string;
  id: string;
  type: ProgramType;
  subType?: ProgramSubType;
  sessions: number;
}

export interface ProgramState {
  error: unknown;
  loading: boolean;
  program?: program | null;
}
export interface program {
  id: string;
  name: string;
  startsAt: string;
  endsAt: string;
  limit: number | null;
  conducted: number;
  scheduled: number;
  participants: { id: string; type: Persona; firstName: string; lastName: string }[];
  type: ProgramType;
  subType?: ProgramSubType;
}
export interface CoachOnboardingState {
  _id: string | null;
  userId: string | null;
  error: unknown;
  loading: boolean;
  step: number;
  completedSteps: CoachOnboardingStep[];
  onboardingFetched: boolean;
}

export type SkippedQuestionMap = {
  questionId: string;
  index: number;
};

export type SkippedQuestionFlag = {
  [questionId: string]: {
    answer_id: string;
    index: number;
  };
};

export interface CoacheeOnboardingState {
  _id: string | null;
  error: unknown;
  loading: boolean;
  displayStep: CoacheeOnboardingDisplayScreen;
  completedSteps: LegacyCoacheeOnboardingStep[];
  assignedSteps: LegacyCoacheeOnboardingStep[];
  nonDisplayableSteps: LegacyCoacheeOnboardingStep[];
  showStrengthReportScreen: boolean;
  onboardingFetched: boolean;
}

export interface CoachSessionAvailabilityType {
  [coachId: string]: CoachSessionAvailability[];
}

export enum QuestionnaireSubmissionStatus {
  NONE = 'NONE',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  SUPPORT_CONTACTED_TIMEOUT = 'SUPPORT_CONTACTED_TIMEOUT',
  SUPPORT_CONTACTED_ZERO_PROPOSALS = 'SUPPORT_CONTACTED_ZERO_PROPOSALS',
  SUPPORT_CONTACTED_MANUAL = 'SUPPORT_CONTACTED_MANUAL'
}

export interface MatchingState {
  loading: boolean;
  industries: string[];
  functionalExpertises: string[];
  matchingSuccessful: boolean | null;
  questionnaireId: string | null;
  categories: Category[];
  skippedQuestionIndexes: SkippedQuestionMap[];
  error: null;
  answers: MatchingAnswer[];
  submissionStatus: QuestionnaireSubmissionStatus | undefined;
  proposals?: CoachProposal[] | null;
  showMoreProposals?: string | boolean;
  hasTimedOut: boolean;
  isRedoingQuestionnaire: boolean;
  coachAvailabilities: CoachSessionAvailabilityType | undefined;
}

type Calendar = {
  id: string;
  name: string;
  timezone: string;
  isDefault?: boolean;
  isReadonly?: boolean;
};

export type ConnectedCalendarAccount = {
  availabilityCheckCalendars: Calendar[];
  availableCalendars: Calendar[];
  email: string;
  id: string;
  provider: string;
  requiresWriteCalendarSelection: boolean;
  status: string;
};
export type BookableResource = {
  availabilityConfiguration: {
    buffer: number;
    slotIncrement: number;
    lastMinuteBookingEnabled?: boolean;
  };
  connectedCalendarAccounts?: ConnectedCalendarAccount[];
  id: string;
  primaryTimezone: string;
  userId: string;
  oAuthUrls: {
    path: string;
    provider: string;
  }[];
};

export interface BookableResourceState {
  error: boolean;
  loading: boolean;
  bookableResource: BookableResource | null;
}

export type CoachState = {
  background: string;
  coachees: string[];
  coachingExperience: string;
  company: string;
  email: string;
  lang: string;
  name: FullName;
  philosophy: string;
  picture: string | null;
  prefix: string;
  roles: UserRole;
  testimonials: string;
  timezone: string;
  title: string;
  username: string;
  workingExperience: string;
};

export interface UserState {
  background: boolean;
  coach: CoachState | Record<string, unknown>;
  coachId: string;
  lastName: string;
  firstName: string;
  coachees: boolean; // should be isCoachee
  coachingExperience: string;
  createdAt: string;
  email: string;
  lang: string;
  lastModified: string;
  loading: boolean;
  name: FullName;
  philosophy: string;
  picture: string | null;
  prefix: string;
  roles: UserRole;
  testimonials: string;
  timezone: string;
  title: string;
  username: string;
  workingExperience: string;
  _id?: string;
  guestUserID?: string;
  regionId?: string;
  // To be completed and also cleaned
}
export type BrowseLearningActivities = {
  [locale: string]: LearningActivity[];
} | null;

export interface LearningActivitiesState {
  loading: boolean;
  browseLearningActivities: BrowseLearningActivities;
  browseTraceId: string | null;
  browseTotalCountForQuery: number;
  groupedLearningActivities?: {
    bookmarked: LearningActivity[];
    completed: LearningActivity[];
    remaining: LearningActivity[];
  };
  groupedLoading: boolean;
  recentlyViewedLoading: boolean;
  recentlyViewedLearningActivities: {
    [locale: string]: LearningActivity[];
  } | null;
  recentlyViewedTraceId: string | null;
}

export interface AppState {
  auth: Record<string, unknown>;
  user: UserState;
  sessions: Record<string, unknown>;
  bookableResource: BookableResourceState;
  documents: Record<string, unknown>;
  appBar: Record<string, unknown>;
  chat: Record<string, unknown>;
  assessment: Record<string, unknown>;
  focusAreas: Record<string, unknown>;
  matching: MatchingState;
  activities: Record<string, unknown>;
  availabilities: AvailabilitiesState;
  learningNuggets: Record<string, unknown>;
  learningActivities: LearningActivitiesState;
  recommmendedNuggets: Record<string, unknown>;
  goals: Record<string, unknown>;
  alertHandling: AlertHandlingState;
  program: ProgramState;
  coachOnboarding: CoachOnboardingState;
  coacheeOnboarding: CoacheeOnboardingState;
}
