export const LEARNING_ACTIVITIES_WITH_FILTERS_CLEAR = 'LEARNING_ACTIVITIES_WITH_FILTERS_CLEAR';
export const LEARNING_ACTIVITIES_WITH_FILTERS_ERROR = 'LEARNING_ACTIVITIES_WITH_FILTERS_ERROR';
export const LEARNING_ACTIVITIES_WITH_FILTER_GET = 'LEARNING_ACTIVITIES_WITH_FILTER_GET';
export const LEARNING_ACTIVITIES_LOADING = 'LEARNING_ACTIVITIES_LOADING';
export const GROUPED_LEARNING_ACTIVITIES_LOADING = 'GROUPED_LEARNING_NUGGETS_LOADING';
export const GROUPED_LEARNING_ACTIVITIES_GET_WITH_QUERY = 'GROUPED_LEARNING_NUGGETS_GET_WITH_QUERY';
export const GROUPED_LEARNING_ACTIVITIES_ERROR_WITH_QUERY =
  'GROUPED_LEARNING_NUGGETS_ERROR_WITH_QUERY';
export const RECENTLY_VIEWED_CLEAR = 'RECENTLY_VIEWED_CLEAR';
export const RECENTLY_VIEWED_ERROR = 'RECENTLY_VIEWED_ERROR';
export const RECENTLY_VIEWED_GET = 'RECENTLY_VIEWED_GET';
export const RECENTLY_VIEWED_LOADING = 'RECENTLY_VIEWED_LOADING';
export const CLEAR_BROWSE_LEARNING_ACTIVITIES = 'CLEAR_BROWSE_LEARNING_ACTIVITIES';
