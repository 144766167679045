import * as Types from './types';

import { mapLearningActivity } from '../learning-activities/reducer';

const initialState = {
  loading: false,
  starterPack: null,
  error: null,
  traceId: null
};

const formatLearningActivities = learningActivities =>
  learningActivities.map(learningActivity => mapLearningActivity(learningActivity));

export const recommendedNuggetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.RECOMMENDED_LEARNING_ACTIVITIES_GET: {
      return {
        ...state,
        loading: false,
        starterPack: action.payload.starterPack
          ? formatLearningActivities(action.payload.starterPack.learningActivities)
          : null,
        newLearningActivities: formatLearningActivities(
          action.payload.newLearningActivities.learningActivities
        ),
        goalLearningActivities: formatLearningActivities(
          action.payload.goalLearningActivities.learningActivities
        ),
        activityRelatedLearningActivities: formatLearningActivities(
          action.payload.activityRelatedLearningActivities.learningActivities
        ),
        trendingLearningActivities: formatLearningActivities(
          action.payload.trendingLearningActivities.learningActivities
        ),
        userLearningActivities: formatLearningActivities(
          action.payload.userLearningActivities.learningActivities
        ),
        error: null,
        traceId: action.traceId
      };
    }
    case Types.RECOMMENDED_LEARNING_ACTIVITIES_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case Types.RECOMMENDED_LEARNING_ACTIVITIES_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    default:
      return state;
  }
};
