import { combineReducers } from 'redux';
import { authReducer } from './auth/reducer';
import { activitiesReducer } from './activities/reducer';
import { userReducer } from './user/reducer';
import { sessionsReducer } from './sessions/reducer';
import { companyBriefingReducer } from './documents/reducer';
import { appBarReducer } from './app-bar/reducer';
import { coachOnboardingReducer } from './coach-onboarding/reducer';
import { coacheeOnboardingReducer } from './coachee-onboarding/reducer';
import { assessmentReducer } from './assessment/reducer';
import { focusAreasReducer } from './focus-areas/reducer';
import { alertHandlingReducer } from './alert-handling/reducer';
import { matchingReducer } from './matching/reducer';
import { learningNuggetsReducer } from './learning-nuggets/reducer';
import { recommendedNuggetsReducer } from './recommended-learning-activities/reducer';
import { goalsReducer } from './goals/reducer';
import { bookmarksReducer } from './bookmarks/reducer';
import { availabilitiesReducer } from './availabilities/reducer';
import { bookableResourceReducer } from './bookableResource/reducer';
import { programReducer } from './program/reducer';
import { collectionsReducer } from './collections/reducer';
import { learningActivitiesReducer } from './learning-activities/reducer';

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  sessions: sessionsReducer,
  documents: companyBriefingReducer,
  appBar: appBarReducer,
  coachOnboarding: coachOnboardingReducer,
  coacheeOnboarding: coacheeOnboardingReducer,
  assessment: assessmentReducer,
  availabilities: availabilitiesReducer,
  bookableResource: bookableResourceReducer,
  focusAreas: focusAreasReducer,
  matching: matchingReducer,
  activities: activitiesReducer,
  collections: collectionsReducer,
  learningNuggets: learningNuggetsReducer,
  learningActivities: learningActivitiesReducer,
  recommmendedNuggets: recommendedNuggetsReducer,
  goals: goalsReducer,
  alertHandling: alertHandlingReducer,
  bookmarks: bookmarksReducer,
  program: programReducer
});
