import { AVAILABILITIES_ERROR, SESSIONS_ERROR } from '../sessions/types';

const loggedActionTypes = [AVAILABILITIES_ERROR, SESSIONS_ERROR];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const logErrors = store => next => action => {
  const { type, payload } = action;
  if (loggedActionTypes.includes(type)) {
    console.error(payload);
  }
  return next(action);
};

export default logErrors;
