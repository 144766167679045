const googleCalendarDefaultLink = 'https://calendar.google.com/calendar/r/eventedit?dates=';
const outlookCalendarDefaultLink = 'https://outlook.live.com/calendar/0/deeplink/compose';
const outlook365CalendarDefaultLink = 'https://outlook.office.com/calendar/deeplink/compose';

const local = {
  account: process.env.NEXT_PUBLIC_ACCOUNT_API || 'https://account.dev.coachhub.cloud/graphql',
  enbaleLoginIssueFix: process.env.REACT_APP_ENABLE_LOGIN_FIX_ISSUE,
  FEurl: 'http://localhost:3000',
  accessibilityUrl:
    process.env.REACT_APP_ACCESSIBILITY_URI ||
    'https://www.coachhub.com/coachhub-accessibility-statement',
  copyrightUrl: 'https://citations.coachhub.com',
  projectId:
    process.env.REACT_APP_ACCESSIBILITY_PROJECT_ID || 'c16ab3f0-40cd-449a-b5f3-1dc325e931a1',
  openTokApiKey: '46309232',
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
  meen: 'd06ZBd1MxuKUUhb3Ih',
  launchDarklyDefaultUser: 'local-dev', // to reduce monthly active users (MAUs) on launch darkly
  launchDarkly: {
    clientSideID: process.env.LD_CLIENT_SIDE_ID_DEV || '5f4666035211630910b1584d',
    options: {
      allAttributesPrivate: false,
      baseUrl: process.env.REACT_APP_LAUNCHDARKLY_RELAY_PROXY_URL,
      eventsUrl: process.env.REACT_APP_LAUNCHDARKLY_RELAY_PROXY_URL,
      streamUrl: process.env.REACT_APP_LAUNCHDARKLY_RELAY_PROXY_URL,
      streaming: false,
      sendEvents: false,
      bootstrap: 'localStorage'
    },
    reactOptions: {
      useCamelCaseFlagKeys: false
    },
    allAttributesPrivate: true,
    sdkKey: process.env.REACT_APP_LAUNCH_DARKLY_SDK_KEY
  },
  localizations: {
    // TODO: should be moved to config var
    backendUri:
      process.env.REACT_APP_LOCALIZATION_BACKEND_URI ||
      'https://lokalise.coachhub.com/development/',
    forceUseOnlyBundledResources:
      process.env.REACT_APP_LOCALIZATION_FORCE_USE_ONLY_BUNDLED_RESOURCES === 'true' || false
  },
  sentry: {
    active: false,
    environment: 'local',
    dataSourceName: 'https://10fb8d6edcf743c882c5cbfd128df6aa@o472871.ingest.sentry.io/5507104'
  },
  communication: {
    showMessageStatus: true
  },
  documents: {
    baseUrl:
      process.env.REACT_APP_DOCUMENTS_BASE_URL || 'https://apollo-document.dev.coachhub.cloud'
  },
  showPhoneDialin: true,
  hotjar: {
    enabled: true,
    snippetVersion: process.env.REACT_APP_HOTJAR_SNIPPET_VERSION,
    trackingCode: process.env.REACT_APP_HOTJAR_TRACKING_CODE
  },
  useComponentsLibrary: false,
  screenSharingFeature: true,
  sessionOverview: {
    active: true,
    displaySessionIdColumn: true
  },
  daimlerOrganizationId: process.env.REACT_APP_DAIMLER_ORG_ID || '',
  sessionNotificationReminderTrigger: process.env.REACT_APP_FA_SESSION_REMINDER_HOURS_TRIGGER || 36,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || 'identity.dev.coachhub.cloud',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI || 'http://localhost:3000',
    tokenExchangeRefreshRateInSeconds: process.env.REACT_APP_TOKEN_EXCHANGE_REFRESH_RATE || 50,
    tokenExchangeThresholdInSeconds: process.env.REACT_APP_TOKEN_EXCHANGE_THRESHOLD || 50
  },
  googleCalendarLink: googleCalendarDefaultLink,
  outlookCalendarLink: outlookCalendarDefaultLink,
  outlook365CalendarLink: outlook365CalendarDefaultLink,
  helpCenter: {
    baseUrl: 'https://support.coachhub.io/hc',
    coachPersona: '360003222097-I-m-a-Coach',
    coacheePersona: '4403239767569-I-m-a-Coachee'
  },
  supportEmail: 'support@coachhub.com',
  videoSessions: {
    performTechCheck: false
  },
  coachSelectionTipsPath: '/coach-proposal-tips',
  organizationIdForCoachOnboardingQuestionnaire: 'org_03c3c7ba-c29c-4bc0-ada8-3969630d7b65',
  tenants: {
    global: {
      coach: {
        url: 'https://coach.dev.coachhub.cloud'
      },
      account: {
        url: 'https://account.dev.coachhub.cloud/graphql'
      },
      availability: {
        url: process.env.REACT_APP_AV_BASE_URL || 'https://availability.dev.coachhub.cloud'
      },
      matching: {
        url: process.env.REACT_APP_MATCHING_URL || 'https://matching.dev.coachhub.cloud',
        timeout: process.env.MATCHING_TIMEOUT_THRESHOLD || 20000
      },
      coaching: {
        url: process.env.REACT_APP_COACHING_BASE_URL || 'https://coaching.dev.coachhub.cloud'
      },
      learningEngagement: {
        url: process.env.REACT_APP_LE_BASE_URL || 'https://learning-engagement.dev.coachhub.cloud'
      }
    },
    local: {
      session: {
        urls: {
          'eu-1': process.env.REACT_APP_SESSION_URL || 'https://session.dev.coachhub.cloud',
          'us-1': process.env.REACT_APP_SESSION_URL_US || 'https://session.dev.us-1.coachhub.cloud'
        }
      },
      document: {
        urls: {
          'eu-1': 'https://document.dev.coachhub.cloud',
          'us-1': 'https://document.dev.us-1.coachhub.cloud'
        }
      },
      organization: {
        urls: {
          'eu-1':
            process.env.REACT_APP_ORGANIZATION_URL ||
            'https://organization.dev.coachhub.cloud/graphql',
          'us-1':
            process.env.REACT_APP_ORGANIZATION_URL_US ||
            'https://organization.dev.us-1.coachhub.cloud/graphql'
        }
      },
      communication: {
        urls: {
          'eu-1': 'https://communication.dev.coachhub.cloud',
          'us-1': 'https://communication.dev.us-1.coachhub.cloud'
        }
      },
      lineManager: {
        urls: {
          'eu-1':
            process.env.REACT_APP_LINE_MANAGER_URL || 'https://line-manager.dev.coachhub.cloud',
          'us-1':
            process.env.REACT_APP_LINE_MANAGER_URL_US ||
            'https://line-manager.dev.us-1.coachhub.cloud'
        }
      },
      bff: {
        urls: {
          'eu-1': process.env.REACT_APP_BFF_URL || 'https://coaching-web-bff.dev.coachhub.cloud',
          'us-1':
            process.env.REACT_APP_BFF_URL_US || 'https://coaching-web-bff.dev.us-1.coachhub.cloud'
        }
      },
      conferencing: {
        urls: {
          'eu-1':
            process.env.REACT_APP_CONFERENCING_URL || 'https://conferencing.dev.coachhub.cloud',
          'us-1':
            process.env.REACT_APP_CONFERENCING_URL_US ||
            'https://conferencing.dev.us-1.coachhub.cloud'
        }
      }
    }
  },
  dashboardLink: 'https://company-dashboard.dev.coachhub.cloud',
  zendesk: {
    apiKey: process.env.REACT_APP_ZENDESK_API_KEY,
    testCompany: process.env.REACT_APP_ZENDESK_TEST_COMPANY || 'CoachHub_Test_Organization',
    leaveUsAMessageTicketFormId: process.env.REACT_APP_ZENDESK_LEAVE_US_A_MESSAGE_TICKET_FORM_ID
  }
};

const test = {
  enbaleLoginIssueFix: process.env.REACT_APP_ENABLE_LOGIN_FIX_ISSUE,
  FEurl: 'http://localhost:3000',
  accessibilityUrl:
    process.env.REACT_APP_ACCESSIBILITY_URI ||
    'https://www.coachhub.com/coachhub-accessibility-statement',
  copyrightUrl: 'https://citations.coachhub.com',
  communication: {
    showMessageStatus: false
  },
  documents: {
    baseUrl: 'mock-baseurl'
  },
  localizations: {
    backendUri: '', // we don't use the backend in tests, but bundled resources
    forceUseOnlyBundledResources: true
  },
  sentry: {
    active: false
  },
  sessionOverview: {
    active: true,
    displaySessionIdColumn: true
  },
  screenSharingFeature: true,
  sessionNotificationReminderTrigger: 36,
  showPhoneDialin: true,
  googleCalendarLink: googleCalendarDefaultLink,
  outlookCalendarLink: outlookCalendarDefaultLink,
  outlook365CalendarLink: outlook365CalendarDefaultLink,
  helpCenter: {
    baseUrl: 'https://support.coachhub.io/hc',
    coachPersona: '360003222097-I-m-a-Coach',
    coacheePersona: '4403239767569-I-m-a-Coachee'
  },
  supportEmail: 'support@coachhub.com',
  videoSessions: {
    performTechCheck: false
  },
  coachSelectionTipsPath: '/coach-proposal-tips',
  tenants: {
    global: {
      coach: {
        url: 'mock-coach-service-url'
      },
      account: {
        url: 'https://account.dev.coachhub.cloud/graphql'
      },
      availability: {
        url: process.env.REACT_APP_AV_BASE_URL || 'mock-availability-service-url'
      },
      matching: {
        url: process.env.REACT_APP_MATCHING_URL || 'mock-matching-service-url',
        timeout: process.env.MATCHING_TIMEOUT_THRESHOLD || 20000
      },
      coaching: {
        url: process.env.REACT_APP_COACHING_BASE_URL || 'mock-coaching-service-url'
      },
      learningEngagement: {
        url: 'mock-learninging-engagement-service-url'
      }
    },
    local: {
      session: {
        urls: {
          'eu-1': process.env.REACT_APP_SESSION_URL || 'mock-session-eu-1-service-url',
          'us-1': process.env.REACT_APP_SESSION_URL_US || 'mock-session-us-1'
        }
      },
      document: {
        urls: {
          'eu-1': 'mock-document-eu-1-service-url',
          'us-1': 'mock-document-us-1-service-url'
        }
      },
      organization: {
        urls: {
          'eu-1': process.env.REACT_APP_ORGANIZATION_URL || 'mock-organization-eu-1',
          'us-1': process.env.REACT_APP_ORGANIZATION_URL_US || 'mock-organization-us-1'
        }
      },
      communication: {
        urls: {
          'eu-1': 'mock-communication-url-eu-1',
          'us-1': 'mock-communication-url-us-1'
        }
      },
      lineManager: {
        urls: {
          'eu-1': process.env.REACT_APP_LINE_MANAGER_URL || 'mock-line-manager-service-url',
          'us-1': process.env.REACT_APP_LINE_MANAGER_URL_US || 'mock-line-manager-url-us-1'
        }
      },
      bff: {
        urls: {
          'eu-1': process.env.REACT_APP_BFF_URL || 'mock-bff-service-url',
          'us-1': process.env.REACT_APP_BFF_URL_US || 'mock-bff-service-url-us-1'
        }
      },
      conferencing: {
        urls: {
          'eu-1': process.env.REACT_APP_CONFERENCING_URL || 'mock-conferencing-service-url',
          'us-1': process.env.REACT_APP_CONFERENCING_URL_US || 'mock-conferencing-service-url-us-1'
        }
      }
    }
  },
  dashboardLink: 'https://company-dashboard.dev.coachhub.cloud',
  zendesk: {
    apiKey: process.env.REACT_APP_ZENDESK_API_KEY,
    testCompany: process.env.REACT_APP_ZENDESK_TEST_COMPANY || 'CoachHub_Test_Organization',
    leaveUsAMessageTicketFormId: process.env.REACT_APP_ZENDESK_LEAVE_US_A_MESSAGE_TICKET_FORM_ID
  }
};

const development = {
  account: process.env.NEXT_PUBLIC_ACCOUNT_API || 'https://account.dev.coachhub.cloud/graphql',
  enbaleLoginIssueFix: process.env.REACT_APP_ENABLE_LOGIN_FIX_ISSUE,
  FEurl: process.env.REACT_APP_FRONTEND_URL_DEV || 'https://frontend.dev.coachhub.cloud',
  accessibilityUrl:
    process.env.REACT_APP_ACCESSIBILITY_URI ||
    'https://www.coachhub.com/coachhub-accessibility-statement',
  copyrightUrl: 'https://citations.coachhub.com',
  projectId:
    process.env.REACT_APP_ACCESSIBILITY_PROJECT_ID || 'c16ab3f0-40cd-449a-b5f3-1dc325e931a1',
  openTokApiKey: '46309232',
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
  meen: 'd06ZBd1MxuKUUhb3Ih',
  launchDarklyDefaultUser: 'development-user', // to reduce monthly active users (MAUs) on launch darkly
  launchDarkly: {
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_KEY || '',
    options: {
      baseUrl: process.env.REACT_APP_LAUNCHDARKLY_RELAY_PROXY_URL || undefined,
      eventsUrl: process.env.REACT_APP_LAUNCHDARKLY_RELAY_PROXY_URL || undefined,
      streamUrl: process.env.REACT_APP_LAUNCHDARKLY_RELAY_PROXY_URL || undefined,
      streaming: false,
      bootstrap: 'localStorage'
    },
    reactOptions: {
      useCamelCaseFlagKeys: false
    },
    allAttributesPrivate: true,
    sdkKey: process.env.REACT_APP_LAUNCH_DARKLY_SDK_KEY
  },
  localizations: {
    // TODO: should be moved to config var
    backendUri:
      process.env.REACT_APP_LOCALIZATION_BACKEND_URI ||
      'https://lokalise.coachhub.com/development/',
    forceUseOnlyBundledResources:
      process.env.REACT_APP_LOCALIZATION_FORCE_USE_ONLY_BUNDLED_RESOURCES === 'true' || false
  },
  sentry: {
    active: true,
    environment: 'development',
    dataSourceName: process.env.REACT_APP_SENTRY_DATA_SOURCE_NAME
  },
  communication: {
    showMessageStatus: process.env.REACT_APP_SHOW_MESSAGE_STATUS || true
  },
  documents: {
    baseUrl:
      process.env.REACT_APP_DOCUMENTS_BASE_URL || 'https://apollo-document.dev.coachhub.cloud'
  },
  showPhoneDialin: process.env.REACT_APP_USE_PHONE_DIAL_IN || false,
  hotjar: {
    enabled: true,
    snippetVersion: process.env.REACT_APP_HOTJAR_SNIPPET_VERSION,
    trackingCode: process.env.REACT_APP_HOTJAR_TRACKING_CODE
  },
  useComponentsLibrary: false,
  screenSharingFeature: true,
  sessionOverview: {
    active: process.env.REACT_APP_USE_SESSION_OVERVIEW === 'true' || false,
    displaySessionIdColumn:
      process.env.REACT_APP_USE_SESSION_OVERVIEW_DISPLAY_ID_COLUMN === 'true' || false
  },
  daimlerOrganizationId: process.env.REACT_APP_DAIMLER_ORG_ID || '',
  sessionNotificationReminderTrigger: process.env.REACT_APP_FA_SESSION_REMINDER_HOURS_TRIGGER || 36,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    tokenExchangeRefreshRateInSeconds: process.env.REACT_APP_TOKEN_EXCHANGE_REFRESH_RATE || 50,
    tokenExchangeThresholdInSeconds: process.env.REACT_APP_TOKEN_EXCHANGE_THRESHOLD || 50
  },
  googleCalendarLink: process.env.REACT_APP_GOOGLE_CALENDAR_LINK || googleCalendarDefaultLink,
  outlookCalendarLink: process.env.REACT_APP_OUTLOOK_CALENDAR_LINK || outlookCalendarDefaultLink,
  outlook365CalendarLink: outlook365CalendarDefaultLink,
  helpCenter: {
    baseUrl: 'https://support.coachhub.io/hc',
    coachPersona: '360003222097-I-m-a-Coach',
    coacheePersona: '4403239767569-I-m-a-Coachee'
  },
  supportEmail: 'support@coachhub.com',
  videoSessions: {
    performTechCheck: false
  },
  coachSelectionTipsPath: '/coach-proposal-tips',
  organizationIdForCoachOnboardingQuestionnaire: 'org_03c3c7ba-c29c-4bc0-ada8-3969630d7b65',
  tenants: {
    global: {
      coach: {
        url: process.env.REACT_APP_COACH_BASE_URL || 'https://coach.dev.coachhub.cloud'
      },
      account: {
        url: 'https://account.dev.coachhub.cloud/graphql'
      },
      availability: {
        url: process.env.REACT_APP_AV_BASE_URL || 'https://availability.dev.coachhub.cloud'
      },
      matching: {
        url: process.env.REACT_APP_MATCHING_URL || 'https://matching.dev.coachhub.cloud',
        timeout: process.env.MATCHING_TIMEOUT_THRESHOLD || 20000
      },
      coaching: {
        url: process.env.REACT_APP_COACHING_BASE_URL || 'https://coaching.dev.coachhub.cloud'
      },
      learningEngagement: {
        url: process.env.REACT_APP_LE_BASE_URL || 'https://learning-engagement.dev.coachhub.cloud'
      }
    },
    local: {
      session: {
        urls: {
          'eu-1': process.env.REACT_APP_SESSION_URL || 'https://session.dev.coachhub.cloud',
          'us-1': process.env.REACT_APP_SESSION_URL_US || 'https://session.dev.us-1.coachhub.cloud'
        }
      },
      document: {
        urls: {
          'eu-1': 'https://document.dev.coachhub.cloud',
          'us-1': 'https://document.dev.us-1.coachhub.cloud'
        }
      },
      organization: {
        urls: {
          'eu-1':
            process.env.REACT_APP_ORGANIZATION_URL ||
            'https://organization.dev.coachhub.cloud/graphql',
          'us-1':
            process.env.REACT_APP_ORGANIZATION_URL_US ||
            'https://organization.dev.us-1.coachhub.cloud/graphql'
        }
      },
      communication: {
        urls: {
          'eu-1':
            process.env.REACT_APP_COMMUNICATION_URL || 'https://communication.dev.coachhub.cloud',
          'us-1': 'https://communication.dev.us-1.coachhub.cloud'
        }
      },
      lineManager: {
        urls: {
          'eu-1':
            process.env.REACT_APP_LINE_MANAGER_URL || 'https://line-manager.dev.coachhub.cloud',
          'us-1':
            process.env.REACT_APP_LINE_MANAGER_URL_US ||
            'https://line-manager.dev.us-1.coachhub.cloud'
        }
      },
      bff: {
        urls: {
          'eu-1': process.env.REACT_APP_BFF_URL || 'https://coaching-web-bff.dev.coachhub.cloud',
          'us-1':
            process.env.REACT_APP_BFF_URL_US || 'https://coaching-web-bff.dev.us-1.coachhub.cloud'
        }
      },
      conferencing: {
        urls: {
          'eu-1':
            process.env.REACT_APP_CONFERENCING_URL || 'https://conferencing.dev.coachhub.cloud',
          'us-1':
            process.env.REACT_APP_CONFERENCING_URL_US ||
            'https://conferencing.dev.us-1.coachhub.cloud'
        }
      }
    }
  },
  dashboardLink: 'https://company-dashboard.dev.coachhub.cloud',
  zendesk: {
    apiKey: process.env.REACT_APP_ZENDESK_API_KEY,
    testCompany: process.env.REACT_APP_ZENDESK_TEST_COMPANY || 'CoachHub_Test_Organization',
    leaveUsAMessageTicketFormId: process.env.REACT_APP_ZENDESK_LEAVE_US_A_MESSAGE_TICKET_FORM_ID
  }
};

const staging = {
  account: 'https://account.stage.coachhub.cloud/graphql',
  enbaleLoginIssueFix: process.env.REACT_APP_ENABLE_LOGIN_FIX_ISSUE,
  FEurl: process.env.REACT_APP_FRONTEND_URL_STAGE || 'https://frontend.stage.coachhub.cloud',
  accessibilityUrl:
    process.env.REACT_APP_ACCESSIBILITY_URI ||
    'https://www.coachhub.com/coachhub-accessibility-statement',
  copyrightUrl: 'https://citations.coachhub.com',
  projectId:
    process.env.REACT_APP_ACCESSIBILITY_PROJECT_ID || 'c16ab3f0-40cd-449a-b5f3-1dc325e931a1',
  openTokApiKey: '46447402',
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
  documents: {
    baseUrl:
      process.env.REACT_APP_DOCUMENTS_BASE_URL || 'https://apollo-document.stage.coachhub.cloud'
  },
  meen: 'd06ZBd1MxuKUUhb3Ih',
  launchDarklyDefaultUser: 'staging-user',
  launchDarkly: {
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_KEY || '',
    options: {
      baseUrl: process.env.REACT_APP_LAUNCHDARKLY_RELAY_PROXY_URL || undefined,
      eventsUrl: process.env.REACT_APP_LAUNCHDARKLY_RELAY_PROXY_URL || undefined,
      streamUrl: process.env.REACT_APP_LAUNCHDARKLY_RELAY_PROXY_URL || undefined,
      streaming: false,
      bootstrap: 'localStorage'
    },
    reactOptions: {
      useCamelCaseFlagKeys: false
    },
    allAttributesPrivate: true,
    sdkKey: process.env.REACT_APP_LAUNCH_DARKLY_SDK_KEY
  },
  localizations: {
    // TODO: should be moved to config var
    backendUri:
      process.env.REACT_APP_LOCALIZATION_BACKEND_URI || 'https://lokalise.coachhub.com/staging/',
    forceUseOnlyBundledResources:
      process.env.REACT_APP_LOCALIZATION_FORCE_USE_ONLY_BUNDLED_RESOURCES === 'true' || false
  },
  sentry: {
    active: true,
    environment: 'staging',
    dataSourceName: process.env.REACT_APP_SENTRY_DATA_SOURCE_NAME
  },
  communication: {
    showMessageStatus: process.env.REACT_APP_SHOW_MESSAGE_STATUS || true
  },
  showPhoneDialin: process.env.REACT_APP_USE_PHONE_DIAL_IN || false,
  hotjar: {
    enabled: true,
    snippetVersion: process.env.REACT_APP_HOTJAR_SNIPPET_VERSION,
    trackingCode: process.env.REACT_APP_HOTJAR_TRACKING_CODE
  },
  useComponentsLibrary: false,
  screenSharingFeature: true,
  sessionOverview: {
    active: process.env.REACT_APP_USE_SESSION_OVERVIEW === 'true' || false,
    displaySessionIdColumn:
      process.env.REACT_APP_USE_SESSION_OVERVIEW_DISPLAY_ID_COLUMN === 'true' || false
  },
  daimlerOrganizationId: process.env.REACT_APP_DAIMLER_ORG_ID || '',
  sessionNotificationReminderTrigger: process.env.REACT_APP_FA_SESSION_REMINDER_HOURS_TRIGGER || 36,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    tokenExchangeRefreshRateInSeconds: process.env.REACT_APP_TOKEN_EXCHANGE_REFRESH_RATE || 50,
    tokenExchangeThresholdInSeconds: process.env.REACT_APP_TOKEN_EXCHANGE_THRESHOLD || 50
  },
  googleCalendarLink: process.env.REACT_APP_GOOGLE_CALENDAR_LINK || googleCalendarDefaultLink,
  outlookCalendarLink: process.env.REACT_APP_OUTLOOK_CALENDAR_LINK || outlookCalendarDefaultLink,
  outlook365CalendarLink: outlook365CalendarDefaultLink,
  helpCenter: {
    baseUrl: 'https://support.coachhub.io/hc',
    coachPersona: '360003222097-I-m-a-Coach',
    coacheePersona: '4403239767569-I-m-a-Coachee'
  },
  supportEmail: 'support@coachhub.com',
  videoSessions: {
    performTechCheck: false
  },
  coachSelectionTipsPath: '/coach-proposal-tips',
  organizationIdForCoachOnboardingQuestionnaire: 'org_e09ae8fe-f376-4959-9db5-8cf80e2173e7',
  tenants: {
    global: {
      coach: {
        url: 'https://coach.stage.coachhub.cloud'
      },
      account: {
        url: 'https://account.stage.coachhub.cloud/graphql'
      },
      availability: {
        url: process.env.REACT_APP_AV_BASE_URL || 'https://availability.stage.coachhub.cloud'
      },
      matching: {
        url: process.env.REACT_APP_MATCHING_URL || 'https://matching.stage.coachhub.cloud',
        timeout: process.env.MATCHING_TIMEOUT_THRESHOLD || 20000
      },
      coaching: {
        url: process.env.REACT_APP_COACHING_BASE_URL || 'https://coaching.stage.coachhub.cloud'
      },
      learningEngagement: {
        url: 'https://learning-engagement.stage.coachhub.cloud'
      }
    },
    local: {
      session: {
        urls: {
          'eu-1': process.env.REACT_APP_SESSION_URL || 'https://session.stage.coachhub.cloud',
          'us-1':
            process.env.REACT_APP_SESSION_URL_US || 'https://session.stage.us-1.coachhub.cloud'
        }
      },
      document: {
        urls: {
          'eu-1': 'https://document.stage.coachhub.cloud',
          'us-1': 'https://document.stage.us-1.coachhub.cloud'
        }
      },
      organization: {
        urls: {
          'eu-1':
            process.env.REACT_APP_ORGANIZATION_URL ||
            'https://organization.stage.coachhub.cloud/graphql',
          'us-1':
            process.env.REACT_APP_ORGANIZATION_URL_US ||
            'https://organization.stage.us-1.coachhub.cloud/graphql'
        }
      },
      communication: {
        urls: {
          'eu-1':
            process.env.REACT_APP_COMMUNICATION_URL || 'https://communication.stage.coachhub.cloud',
          'us-1': 'https://communication.stage.us-1.coachhub.cloud'
        }
      },
      lineManager: {
        urls: {
          'eu-1':
            process.env.REACT_APP_LINE_MANAGER_URL || 'https://line-manager.stage.coachhub.cloud',
          'us-1':
            process.env.REACT_APP_LINE_MANAGER_URL_US ||
            'https://line-manager.stage.us-1.coachhub.cloud'
        }
      },
      bff: {
        urls: {
          'eu-1': process.env.REACT_APP_BFF_URL || 'https://coaching-web-bff.stage.coachhub.cloud',
          'us-1':
            process.env.REACT_APP_BFF_URL_US || 'https://coaching-web-bff.stage.us-1.coachhub.cloud'
        }
      },
      conferencing: {
        urls: {
          'eu-1':
            process.env.REACT_APP_CONFERENCING_URL || 'https://conferencing.stage.coachhub.cloud',
          'us-1':
            process.env.REACT_APP_CONFERENCING_URL_US ||
            'https://conferencing.stage.us-1.coachhub.cloud'
        }
      }
    }
  },
  dashboardLink: 'https://company-dashboard.stage.coachhub.cloud',
  zendesk: {
    apiKey: process.env.REACT_APP_ZENDESK_API_KEY,
    testCompany: process.env.REACT_APP_ZENDESK_TEST_COMPANY || 'CoachHub_Test_Organization',
    leaveUsAMessageTicketFormId: process.env.REACT_APP_ZENDESK_LEAVE_US_A_MESSAGE_TICKET_FORM_ID
  }
};

const production = {
  account: 'https://account.prod.coachhub.cloud/graphql',
  enbaleLoginIssueFix: process.env.REACT_APP_ENABLE_LOGIN_FIX_ISSUE,
  FEurl: process.env.REACT_APP_FRONTEND_URL_PROD || 'https://app.coachhub.com',
  accessibilityUrl:
    process.env.REACT_APP_ACCESSIBILITY_URI ||
    'https://www.coachhub.com/coachhub-accessibility-statement',
  copyrightUrl: 'https://citations.coachhub.com',
  projectId:
    process.env.REACT_APP_ACCESSIBILITY_PROJECT_ID_PROD || '6c0e27bb-5a8c-43f1-82d5-485d7aa96217',
  openTokApiKey: '46201312',
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
  meen: 'd06ZBd1MxuKUUhb3Ih',
  launchDarklyDefaultUser: 'production-user',
  launchDarkly: {
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_KEY || '',
    options: {
      baseUrl: process.env.REACT_APP_LAUNCHDARKLY_RELAY_PROXY_URL || undefined,
      eventsUrl: process.env.REACT_APP_LAUNCHDARKLY_RELAY_PROXY_URL || undefined,
      streamUrl: process.env.REACT_APP_LAUNCHDARKLY_RELAY_PROXY_URL || undefined,
      streaming: false,
      bootstrap: 'localStorage'
    },
    reactOptions: {
      useCamelCaseFlagKeys: false
    },
    allAttributesPrivate: true,
    sdkKey: process.env.REACT_APP_LAUNCH_DARKLY_SDK_KEY
  },
  localizations: {
    // TODO: should be moved to config var
    backendUri:
      process.env.REACT_APP_LOCALIZATION_BACKEND_URI || 'https://lokalise.coachhub.com/production/',
    forceUseOnlyBundledResources:
      process.env.REACT_APP_LOCALIZATION_FORCE_USE_ONLY_BUNDLED_RESOURCES === 'true' || false
  },
  sentry: {
    active: true,
    environment: 'production',
    dataSourceName: process.env.REACT_APP_SENTRY_DATA_SOURCE_NAME
  },
  communication: {
    showMessageStatus: process.env.REACT_APP_SHOW_MESSAGE_STATUS || false
  },
  documents: {
    baseUrl:
      process.env.REACT_APP_DOCUMENTS_BASE_URL || 'https://apollo-document.prod.coachhub.cloud'
  },
  showPhoneDialin: process.env.REACT_APP_USE_PHONE_DIAL_IN === 'true' || false,
  hotjar: {
    enabled: true,
    snippetVersion: process.env.REACT_APP_HOTJAR_SNIPPET_VERSION,
    trackingCode: process.env.REACT_APP_HOTJAR_TRACKING_CODE
  },
  useComponentsLibrary: false,
  screenSharingFeature: true,
  sessionOverview: {
    active: process.env.REACT_APP_USE_SESSION_OVERVIEW === 'true' || false,
    displaySessionIdColumn:
      process.env.REACT_APP_USE_SESSION_OVERVIEW_DISPLAY_ID_COLUMN === 'true' || false
  },
  daimlerOrganizationId: process.env.REACT_APP_DAIMLER_ORG_ID || '',
  sessionNotificationReminderTrigger: process.env.REACT_APP_FA_SESSION_REMINDER_HOURS_TRIGGER || 36,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    tokenExchangeRefreshRateInSeconds: process.env.REACT_APP_TOKEN_EXCHANGE_REFRESH_RATE || 50,
    tokenExchangeThresholdInSeconds: process.env.REACT_APP_TOKEN_EXCHANGE_THRESHOLD || 50
  },
  googleCalendarLink: process.env.REACT_APP_GOOGLE_CALENDAR_LINK || googleCalendarDefaultLink,
  outlookCalendarLink: process.env.REACT_APP_OUTLOOK_CALENDAR_LINK || outlookCalendarDefaultLink,
  outlook365CalendarLink: outlook365CalendarDefaultLink,
  helpCenter: {
    baseUrl: 'https://support.coachhub.io/hc',
    coachPersona: '360003222097-I-m-a-Coach',
    coacheePersona: '4403239767569-I-m-a-Coachee'
  },
  supportEmail: 'support@coachhub.com',
  videoSessions: {
    performTechCheck: false
  },
  coachSelectionTipsPath: '/coach-proposal-tips',
  organizationIdForCoachOnboardingQuestionnaire: 'org_5c9f3fb1-d2e7-4d77-8650-0b997eb8c479',
  tenants: {
    global: {
      coach: {
        url: 'https://coach.prod.coachhub.cloud'
      },
      account: {
        url: 'https://account.prod.coachhub.cloud/graphql'
      },
      availability: {
        url: process.env.REACT_APP_AV_BASE_URL || 'https://availability.prod.coachhub.cloud'
      },
      matching: {
        url: process.env.REACT_APP_MATCHING_URL || 'https://matching.prod.coachhub.cloud',
        timeout: process.env.MATCHING_TIMEOUT_THRESHOLD || 20000
      },
      coaching: {
        url: process.env.REACT_APP_COACHING_BASE_URL || 'https://coaching.prod.coachhub.cloud'
      },
      learningEngagement: {
        url: 'https://learning-engagement.prod.coachhub.cloud'
      }
    },
    local: {
      session: {
        urls: {
          'eu-1': process.env.REACT_APP_SESSION_URL || 'https://session.prod.coachhub.cloud',
          'us-1': process.env.REACT_APP_SESSION_URL_US || 'https://session.prod.us-1.coachhub.cloud'
        }
      },
      document: {
        urls: {
          'eu-1': process.env.REACT_APP_DOCUMENT_BASE_URL || 'https://document.prod.coachhub.cloud',
          'us-1':
            process.env.REACT_APP_DOCUMENT_BASE_URL_US ||
            'https://document.prod.us-1.coachhub.cloud'
        }
      },
      organization: {
        urls: {
          'eu-1':
            process.env.REACT_APP_ORGANIZATION_URL ||
            'https://organization.prod.coachhub.cloud/graphql',
          'us-1':
            process.env.REACT_APP_ORGANIZATION_URL_US ||
            'https://organization.prod.us-1.coachhub.cloud/graphql'
        }
      },
      communication: {
        urls: {
          'eu-1':
            process.env.REACT_APP_COMMUNICATION_URL || 'https://communication.prod.coachhub.cloud',
          'us-1':
            process.env.REACT_APP_COMMUNICATION_URL_US ||
            'https://communication.prod.us-1.coachhub.cloud'
        }
      },
      lineManager: {
        urls: {
          'eu-1':
            process.env.REACT_APP_LINE_MANAGER_URL || 'https://line-manager.prod.coachhub.cloud',
          'us-1':
            process.env.REACT_APP_LINE_MANAGER_URL_US ||
            'https://line-manager.prod.us-1.coachhub.cloud'
        }
      },
      bff: {
        urls: {
          'eu-1': process.env.REACT_APP_BFF_URL || 'https://coaching-web-bff.prod.coachhub.cloud',
          'us-1':
            process.env.REACT_APP_BFF_URL_US || 'https://coaching-web-bff.prod.us-1.coachhub.cloud'
        }
      },
      conferencing: {
        urls: {
          'eu-1':
            process.env.REACT_APP_CONFERENCING_URL || 'https://conferencing.prod.coachhub.cloud',
          'us-1':
            process.env.REACT_APP_CONFERENCING_URL_US ||
            'https://conferencing.prod.us-1.coachhub.cloud'
        }
      }
    }
  },
  dashboardLink: 'https://company-dashboard.prod.coachhub.cloud',
  zendesk: {
    apiKey: process.env.REACT_APP_ZENDESK_API_KEY,
    testCompany: process.env.REACT_APP_ZENDESK_TEST_COMPANY || 'CoachHub_Test_Organization',
    leaveUsAMessageTicketFormId: process.env.REACT_APP_ZENDESK_LEAVE_US_A_MESSAGE_TICKET_FORM_ID
  }
};

export default {
  local,
  test,
  development,
  staging,
  production
}[process.env.REACT_APP_ENV];
