import 'array-flat-polyfill';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import HttpsRedirect from 'react-https-redirect';
import CONFIG_ENV from './config/config-env';
import initSentryMonitoring from './utils/sentry';
import store from './store/store';

const App = lazy(() => import('./app/App'));
const PreviewEditor = lazy(() => import('./lib/PreviewEditor'));

const RedirectedApp = () => {
  return (
    <Router>
      <Suspense fallback={null}>
        <Switch>
          <Route path='/storyblok-preview/*'>
            <Provider store={store}>
              <PreviewEditor />
            </Provider>
          </Route>
          <Route path='*'>
            <HttpsRedirect>
              <App />
            </HttpsRedirect>
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

const main = () => {
  const {
    sentry: { active, dataSourceName, environment }
  } = CONFIG_ENV;
  if (active) initSentryMonitoring(dataSourceName, environment);

  ReactDOM.render(<RedirectedApp />, document.getElementById('root'));
};

main();
