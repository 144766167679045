import * as Types from './types';

const initialState = {
  error: null,
  loading: false
};

export const bookableResourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_BOOKABLE_RESOURCE: {
      return {
        ...state,
        loading: false,
        bookableResource: action.payload
      };
    }

    case Types.UPDATE_BOOKABLE_RESOURCE: {
      return {
        ...state,
        loading: false,
        bookableResource: {
          ...state.bookableResource,
          availabilityConfiguration: {
            ...action.payload
          }
        },
        error: null
      };
    }

    case Types.BOOKABLE_RESOURCE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    case Types.BOOKABLE_RESOURCE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    default:
      return state;
  }
};
