import { CoachOnboardingState } from '../types';
import * as Types from './types';

export type CoachOnboardingAction = {
  type: string;
  payload: any;
};

const initialState = {
  _id: null,
  error: null,
  loading: false,
  step: 0,
  completedSteps: [],
  userId: null,
  onboardingFetched: false
};

export const coachOnboardingReducer = (
  state: CoachOnboardingState = initialState,
  action: CoachOnboardingAction
) => {
  switch (action.type) {
    case Types.COACH_ONBOARDING_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case Types.COACH_ONBOARDING_LOADING:
      return {
        ...state,
        loading: true
      };
    case Types.COACH_ONBOARDING_NEXT_STEP:
      return {
        ...state,
        step: action.payload
      };
    case Types.COACH_ONBOARDING_GET:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null
      };
    case Types.COACH_ONBOARDING_UPDATE_COMPLETED_STEP:
      return {
        ...state,
        completedSteps: [...state.completedSteps, action.payload.step],
        loading: false,
        error: null
      };
    default:
      return state;
  }
};
