import { AlertHandlingState } from '../types';
import { AnyAction } from 'redux';
import { HIDE_GLOBAL_ALERT, SET_GLOBAL_ALERT } from './types';

const initState: AlertHandlingState = {
  isOpen: false
};

export const alertHandlingReducer = (state: AlertHandlingState = initState, action: AnyAction) => {
  switch (action.type) {
    case SET_GLOBAL_ALERT:
      return { isOpen: true, data: action.payload };
    case HIDE_GLOBAL_ALERT:
      return { isOpen: false, data: undefined };
    default:
      return state;
  }
};
