import * as Types from './types';

const initialState = { openInNewTab: false, displayOnboardingAppBar: false };

export const appBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.OPEN_IN_NEW_TAB: {
      return {
        ...state,
        openInNewTab: action.payload
      };
    }
    default:
      return state;
  }
};
