export const FOCUS_AREAS_ERROR = 'FOCUS_AREAS_ERROR';
export const FOCUS_AREAS_GET = 'FOCUS_AREAS_GET';
export const FOCUS_AREAS_LOADING = 'FOCUS_AREAS_LOADING';
export const FOCUS_AREAS_SELECTION_GET = 'FOCUS_AREAS_SELECTION_GET';
export const FOCUS_AREAS_SELECTION_POST = 'FOCUS_AREAS_SELECTION_POST';
export const FOCUS_AREAS_SELECTION_DELETE = 'FOCUS_AREAS_SELECTION_DELETE';
export const FOCUS_AREAS_RATING_POST = 'FOCUS_AREAS_RATING_POST';
export const FOCUS_AREAS_RATING_GET = 'FOCUS_AREAS_RATING_GET';
export const FOCUS_AREAS_HIDE_NOTIFICATION = 'FOCUS_AREAS_HIDE_NOTIFICATION';
export const FOCUS_AREAS_RATED_GET = 'FOCUS_AREAS_RATED_GET';
export const FOCUS_AREAS_ALL_RATINGS_GET = 'FOCUS_AREAS_ALL_RATINGS_GET';
export const FOCUS_AREAS_ALL_RATINGS_POST = 'FOCUS_AREAS_ALL_RATINGS_POST';
