import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import logSessionsError from './middlewares/log-errors';
import rootReducer from './rootReducer';
import * as Sentry from '@sentry/react';
import CONFIG_ENV from '../config/config-env';
import mapDataForSentry from '../utils/sanitiseObjectForSentry';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => {
    /* eslint no-prototype-builtins: "off" */
    if (action?.hasOwnProperty('payload') && action?.payload?.hasOwnProperty('data')) {
      const payloadWithIds = mapDataForSentry({ data: action.payload.data });
      return { ...action, payload: payloadWithIds };
    }
    if (action?.hasOwnProperty('payload')) {
      const payloadWithIds = mapDataForSentry({ data: action.payload });
      return { ...action, payload: payloadWithIds };
    }

    return action;
  },
  stateTransformer: state => {
    const transformedState = mapDataForSentry({ data: state, mappingState: true });
    return transformedState;
  }
});
const middlewares = [thunk, logSessionsError];
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));
const storeWithSentry = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
);
const exportedStore = CONFIG_ENV.sentry.active ? storeWithSentry : store;
export default exportedStore;
