export const LEARNING_NUGGETS_ERROR = 'LEARNING_NUGGETS_ERROR';
export const LEARNING_NUGGETS_LOADING = 'LEARNING_NUGGETS_LOADING';
export const ASSIGNED_LEARNING_NUGGETS_GET = 'ASSIGNED_LEARNING_NUGGETS_GET';
export const ASSIGNED_LEARNING_NUGGETS_DELETE = 'ASSIGNED_LEARNING_NUGGETS_DELETE';
export const ASSIGNED_LEARNING_NUGGETS_COMPLETE = 'ASSIGNED_LEARNING_NUGGETS_COMPLETE';
export const LEARNING_NUGGETS_ASSIGNMENT_RESULTS = 'LEARNING_NUGGETS_ASSIGNMENT_RESULTS';
export const CLEAR_LEARNING_NUGGETS_WITH_FILTERS = 'CLEAR_LEARNING_NUGGETS_WITH_FILTERS';
export const LEARNING_NUGGETS_GET_SINGLE = 'LEARNING_NUGGETS_GET_SINGLE';
export const CLEAR_LEARNING_NUGGETS_SINGLE = 'CLEAR_LEARNING_NUGGETS_SINGLE';
export const LEARNING_NUGGET_MARK_AS_COMPLETED = 'LEARNING_NUGGET_MARK_AS_COMPLETED';
export const LEARNING_NUGGET_SEND_RATING = 'LEARNING_NUGGET_SEND_RATING';
export const LEARNING_NUGGET_OPENED = 'LEARNING_NUGGET_OPENED';
export const LEARNING_NUGGET_PDF_OPENED = 'LEARNING_NUGGET_PDF_OPENED';
export const LEARNING_NUGGET_PDF_COMPLETED = 'LEARNING_NUGGET_PDF_COMPLETED';
