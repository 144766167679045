import { Collection } from '../../model/model';
import { mapLearningActivityCollection } from '../learning-activities/reducer';
import * as Types from './types';

interface ICollectionsReducerState {
  loading: boolean;
  detailCollection?: Collection;
  traceId?: string;
  error?: any;
}

const initialState: ICollectionsReducerState = {
  loading: false,
  detailCollection: undefined,
  traceId: undefined,
  error: undefined
};

export const collectionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Types.COLLECTIONS_GET_SINGLE: {
      return {
        ...state,
        loading: false,
        detailCollection: mapLearningActivityCollection(action.payload),
        error: undefined
      };
    }
    case Types.COLLECTION_OPENED: {
      return {
        ...state,
        loading: false
      };
    }
    case Types.COLLECTION_SEND_RATING: {
      return {
        ...state,
        loading: false,
        detailCollection: {
          ...state.detailCollection,
          rating: action.payload.rating,
          feedbackMessage: action.payload.feedbackMessage
        }
      };
    }
    case Types.CLEAR_COLLECTIONS_SINGLE: {
      return {
        ...state,
        detailCollection: undefined,
        traceId: undefined,
        error: undefined
      };
    }
    default:
      return state;
  }
};
