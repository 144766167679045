import * as Types from './types';

const initialState = {
  assessmentId: '',
  assessmentResultId: '',
  content: [
    {
      id: '1',
      title: '',
      description: '',
      image: '',
      questions: [
        {
          id: '1',
          title: ''
        }
      ]
    }
  ],
  allRatings: [],
  ratingsPosted: false,
  results: [],
  error: null
};

export const assessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.ASSESSMENT_GET: {
      return {
        ...state,
        loading: false,
        assessmentId: action.payload.id,
        content: action.payload.categories,
        error: null
      };
    }
    case Types.ASSESSMENT_RESULTS_GET: {
      return {
        ...state,
        loading: false,
        results: action.payload.resultScores,
        assessmentResultId: action.payload.id,
        calculatedAt: action.payload.calculatedAt,
        ...action.payload,
        error: null
      };
    }
    case Types.ASSESSMENT_RATING_POST: {
      return {
        ...state,
        ratingsPosted: true
      };
    }
    case Types.ASSESSMENT_RATING_POST_RESET: {
      return {
        ...state,
        allRatings: [],
        ratingsPosted: false
      };
    }
    case Types.ASSESSMENT_RATING_SAVE: {
      return {
        ...state,
        allRatings: [
          ...state.allRatings.filter(item => item.id !== action.payload.id),
          action.payload
        ]
      };
    }
    case Types.ASSESSMENT_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case Types.ASSESSMENT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    default:
      return state;
  }
};
