import * as Types from './types';

const initialState = {
  availabilities: {
    recurring: [],
    onetime: []
  },
  error: null,
  loading: false
};

export const availabilitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_RECURRING_AVAILABILITIES: {
      return {
        ...state,
        loading: false,
        recurring: action.payload
      };
    }

    case Types.UPDATE_RECURRING_AVAILABILITIES: {
      return {
        ...state,
        loading: false,
        availabilities: action.payload,
        error: null
      };
    }

    case Types.GET_ONETIME_AVAILABILITIES: {
      return {
        ...state,
        loading: false,
        onetime: action.payload
      };
    }

    case Types.UPDATE_SINGLE_AVAILABILITY: {
      return {
        ...state,
        loading: false,
        availabilities: action.payload,
        error: null
      };
    }

    case Types.REMOVE_SINGLE_AVAILABILITIES: {
      return {
        ...state,
        loading: false,
        availabilities: state.onetime.filter(onetime => onetime.id !== action.payload),
        error: null
      };
    }

    default:
      return state;
  }
};
