import * as Types from './types';

const initialState = {
  goals: [],
  error: null,
  loading: false
};

export const goalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GOALS_GET_BY_COACHEE: {
      return {
        ...state,
        loading: false,
        currentGoal: null,
        goals: action.payload,
        error: null
      };
    }
    case Types.GOALS_GET_BY_ID: {
      return {
        ...state,
        loading: false,
        currentGoal: action.payload,
        error: null
      };
    }
    case Types.GOALS_POST: {
      return {
        ...state,
        currentGoal: null,
        goals: [...state.goals, action.payload].filter(
          (v, i, a) => a.findIndex(t => t.id === v.id) === i
        )
      };
    }
    case Types.UPDATE_MILESTONE: {
      /**
       * Selects a goal by id and overrides the milestone by first removing
       * the milestone from the goals milestone array with given id
       * and then adding the new milestone from the payload
       */
      const selectedGoal = state.goals.find(g => g.id === action.payload.goalId);
      return {
        ...state,
        goals: [
          ...state.goals.filter(g => g.id !== action.payload.goalId),
          {
            ...selectedGoal,
            milestones: [
              ...selectedGoal.milestones.filter(ms => ms.id !== action.payload.milestone.id),
              action.payload.milestone
            ]
          }
        ]
      };
    }
    case Types.GOALS_POST_BY_ID: {
      return {
        ...state,
        currentGoal: null,
        goals: [...state.goals.filter(ac => ac.id !== action.payload.id), action.payload]
      };
    }
    case Types.GOALS_DELETE: {
      return {
        ...state,
        goals: [...state.goals.filter(ac => ac.id !== action.payload)]
      };
    }
    case Types.GOALS_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case Types.GOALS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    default:
      return state;
  }
};
