/* eslint-disable no-restricted-syntax */

const validKeysArray = ['id', 'Id', 'iD', 'ID', '_id'];
const invalidKeysArray = ['video', 'Video'];

export const isWantedProperty = (string, array) => array.find(item => string.includes(item));
export const mapInvalidKeyVariations = (keysArray, variationsArray) =>
  keysArray
    .map(key => {
      return variationsArray.map(variation => {
        return key.replace('id', variation);
      });
    })
    .flat(1);

export const toIdPropsOnly = (
  obj,
  keys = validKeysArray,
  invalidKeys = mapInvalidKeyVariations(invalidKeysArray, validKeysArray)
) => {
  let newObj = {};
  for (const key in obj) {
    if (isWantedProperty(key, keys) && !isWantedProperty(key, invalidKeys)) {
      newObj = { ...newObj, [key]: obj[key] };
    }
  }
  return newObj;
};

const mapDataForSentry = ({ data, mappingState = false } = {}) => {
  let mappedData = null;
  if (mappingState) {
    for (const key in data) {
      if (key) {
        mappedData = { ...mappedData, [key]: toIdPropsOnly(data[key]) };
      }
    }
    return mappedData;
  }
  if (Array.isArray(data)) {
    mappedData = data.map(item => toIdPropsOnly(item));
  } else {
    mappedData = toIdPropsOnly(data);
  }
  return mappedData;
};

export default mapDataForSentry;
