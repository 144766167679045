import * as Types from './types';

const initialState = {
  activities: [],
  currentActivity: null,
  error: null,
  loading: false
};

export const activitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.ACTIVITIES_GET_BY_COACHEE: {
      return {
        ...state,
        loading: false,
        currentActivity: null,
        activities: action.payload,
        error: null
      };
    }
    case Types.ACTIVITIES_GET_BY_ID: {
      const activities = state.activities.map(item => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      return {
        ...state,
        activities,
        loading: false,
        currentActivity: action.payload,
        error: null
      };
    }
    case Types.ACTIVITIES_POST: {
      return {
        ...state,
        currentActivity: null,
        activities: [...state.activities, action.payload]
      };
    }
    case Types.ACTIVITIES_UPDATE_BY_ID: {
      return {
        ...state,
        currentActivity: null,
        activities: [...state.activities.filter(ac => ac.id !== action.payload.id), action.payload]
      };
    }
    case Types.ACTIVITIES_DELETE: {
      return {
        ...state,
        activities: [...state.activities.filter(ac => ac.id === action.payload.id)]
      };
    }
    case Types.ACTIVITIES_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case Types.ACTIVITIES_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    default:
      return state;
  }
};
