export const GET_RECURRING_AVAILABILITIES = 'GET_RECURRING_AVAILABILITIES';
export const GET_ONETIME_AVAILABILITIES = 'GET_ONETIME_AVAILABILITIES';

export const UPDATE_RECURRING_AVAILABILITIES = 'UPDATE_RECURRING_AVAILABILITIES';
export const UPDATE_SINGLE_AVAILABILITY = 'UPDATE_SINGLE_AVAILABILITY';

export const AVAILABILITIES_LOADING = 'AVAILABILITIES_LOADING';
export const AVAILABILITIES_ERROR = 'AVAILABILITIES_ERROR';

export const REMOVE_SINGLE_AVAILABILITIES = 'REMOVE_SINGLE_AVAILABILITIES';
