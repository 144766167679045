export const BOOKMARKS_ERROR = 'BOOKMARKS_ERROR';
export const BOOKMARKS_LOADING = 'BOOKMARKS_LOADING';

export const BOOKMARK_LISTS_GET = 'BOOKMARK_LISTS_GET';
export const BOOKMARK_LISTS_POST = 'BOOKMARK_LISTS_POST';
export const BOOKMARK_LIST_GET_SINGLE = 'BOOKMARK_LIST_GET_SINGLE';
export const BOOKMARK_LISTS_UPDATE_SINGLE = 'BOOKMARK_LISTS_UPDATE_SINGLE';
export const BOOKMARK_LISTS_DELETE_SINGLE = 'BOOKMARK_LISTS_DELETE_SINGLE';

export const BOOKMARKS_DELETE_SINGLE = 'BOOKMARKS_DELETE_SINGLE';
export const BOOKMARKS_GET_SINGLE = 'BOOKMARKS_GET_SINGLE';
export const BOOKMARKS_POST = 'BOOKMARKS_POST';
export const BOOKMARKS_CLEAR_SINGLE = 'BOOKMARKS_CLEAR_SINGLE';
export const BOOKMARKS_CLEAR_ALL_LISTS = 'BOOKMARKS_CLEAR_ALL_LISTS';
export const BOOKMARKS_CLEAR_LAST_ADDED_BOOKMARK_LIST = 'BOOKMARKS_CLEAR_LAST_ADDED_BOOKMARK_LIST';
