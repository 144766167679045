import * as Types from './types';
import { removeEmptyObjectsFromArray } from '../../utils/helper';

const initialState = {
  loading: false,
  allFocusAreas: [],
  selected: [],
  rated: [],
  allRated: [],
  ratedFocusAreas: [],
  firstScreen: true,
  isRated: false,
  error: null,
  focusAreaNotification: true
};

const filterFocusAreaRatingPairs = ratedFocusAreas =>
  ratedFocusAreas.filter(
    (element, _index, array) =>
      array.find(el => el.focusAreaId === element.focusAreaId && el.type === 'CURRENT') &&
      array.find(el => el.focusAreaId === element.focusAreaId && el.type === 'GOAL')
  );

export const focusAreasReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.FOCUS_AREAS_GET: {
      return {
        ...state,
        loading: false,
        allFocusAreas: removeEmptyObjectsFromArray(action.payload),
        error: null
      };
    }
    case Types.FOCUS_AREAS_SELECTION_GET: {
      return {
        ...state,
        loading: false,
        selected: action.payload,
        firstScreen: false,
        error: null
      };
    }
    case Types.FOCUS_AREAS_SELECTION_POST: {
      return {
        ...state,
        selected: [...state.selected, action.payload],
        loading: false,
        error: null
      };
    }
    case Types.FOCUS_AREAS_SELECTION_DELETE: {
      return {
        ...state,
        selected: [
          ...state.selected.filter(item => item.focusAreaId !== action.payload.focusAreaId)
        ],
        loading: false,
        error: null
      };
    }
    case Types.FOCUS_AREAS_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case Types.FOCUS_AREAS_RATING_POST: {
      return {
        ...state,
        loading: false,
        rated: [
          ...state.rated.map(item => {
            if (
              item.focusAreaId === action.payload.focusAreaId &&
              item.type === action.payload.type
            ) {
              return {
                ...action.payload
              };
            }
            return item;
          })
        ],
        isRated: true,
        error: null
      };
    }
    case Types.FOCUS_AREAS_ALL_RATINGS_POST: {
      const getRated = arr => {
        return arr.map(item => {
          if (item.focusAreaId === action.payload.focusAreaId) {
            /**
             * ratings array should always contain a pair of CURRENT and GOAL
             * rating for given focus area
             */
            const rating = action.payload.ratings.filter(el => el.type === item.type)[0];
            if (rating) {
              return {
                ...rating,
                coacheeId: action.payload.coacheeId,
                focusAreaId: action.payload.focusAreaId
              };
            }
          }
          return item;
        });
      };
      return {
        ...state,
        loading: false,
        rated: [getRated(state.rated)],
        isRated: true,
        error: null
      };
    }
    case Types.FOCUS_AREAS_RATING_GET: {
      return {
        ...state,
        loading: false,
        rated: action.payload,
        isRated: action.payload.length > 0,
        error: null
      };
    }
    case Types.FOCUS_AREAS_HIDE_NOTIFICATION: {
      return {
        ...state,
        focusAreaNotification: false
      };
    }
    case Types.FOCUS_AREAS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case Types.FOCUS_AREAS_RATED_GET: {
      return {
        ...state,
        loading: false,
        rated: filterFocusAreaRatingPairs(action.payload.focusAreasRatings),
        isRated: action.payload.focusAreasRatings.length > 0,
        selected: action.payload.focusAreasSelection,
        allFocusAreas: action.payload.allFocusAreas,
        ratedFocusAreas: action.payload.focusAreasSelection.reduce(
          (frameworkSelectedFocusAreas, currentSelection) => {
            const focusAreaData = action.payload.focusAreas.find(
              fa => currentSelection.focusAreaId === fa.id
            );
            if (focusAreaData) {
              frameworkSelectedFocusAreas.push(focusAreaData);
            }

            return frameworkSelectedFocusAreas;
          },
          []
        )
      };
    }
    case Types.FOCUS_AREAS_ALL_RATINGS_GET: {
      return {
        ...state,
        loading: false,
        allRated: action.payload.map(element => ({
          ...element,
          ratings: filterFocusAreaRatingPairs(element.ratings)
        }))
      };
    }
    default:
      return state;
  }
};
