import moment from 'moment';
import 'moment-timezone';

export default [
  { label: '{UTC} Niue', tzCode: 'Pacific/Niue' },
  { label: '{UTC} Pago Pago', tzCode: 'Pacific/Pago_Pago' },
  { label: '{UTC} Hawaii Time', tzCode: 'Pacific/Honolulu' },
  { label: '{UTC} Rarotonga', tzCode: 'Pacific/Rarotonga' },
  { label: '{UTC} Tahiti', tzCode: 'Pacific/Tahiti' },
  { label: '{UTC} Marquesas', tzCode: 'Pacific/Marquesas' },
  { label: '{UTC} Alaska Time', tzCode: 'America/Anchorage' },
  { label: '{UTC} Gambier', tzCode: 'Pacific/Gambier' },
  { label: '{UTC} Pacific Time', tzCode: 'America/Los_Angeles' },
  { label: '{UTC} Pacific Time - Tijuana', tzCode: 'America/Tijuana' },
  { label: '{UTC} Pacific Time - Vancouver', tzCode: 'America/Vancouver' },
  {
    label: '{UTC} Pacific Time - Whitehorse',
    tzCode: 'America/Whitehorse'
  },
  { label: '{UTC} Pitcairn', tzCode: 'Pacific/Pitcairn' },
  { label: '{UTC} Mountain Time', tzCode: 'America/Denver' },
  { label: '{UTC} Mountain Time - Arizona', tzCode: 'America/Phoenix' },
  {
    label: '{UTC} Mountain Time - Chihuahua, Mazatlan',
    tzCode: 'America/Mazatlan'
  },
  {
    label: '{UTC} Mountain Time - Dawson Creek',
    tzCode: 'America/Dawson_Creek'
  },
  { label: '{UTC} Mountain Time - Edmonton', tzCode: 'America/Edmonton' },
  {
    label: '{UTC} Mountain Time - Hermosillo',
    tzCode: 'America/Hermosillo'
  },
  {
    label: '{UTC} Mountain Time - Yellowknife',
    tzCode: 'America/Yellowknife'
  },
  { label: '{UTC} Belize', tzCode: 'America/Belize' },
  { label: '{UTC} Central Time', tzCode: 'America/Chicago' },
  {
    label: '{UTC} Central Time - Mexico City',
    tzCode: 'America/Mexico_City'
  },
  { label: '{UTC} Central Time - Regina', tzCode: 'America/Regina' },
  {
    label: '{UTC} Central Time - Tegucigalpa',
    tzCode: 'America/Tegucigalpa'
  },
  { label: '{UTC}  Central Time - Winnipeg', tzCode: 'America/Winnipeg' },
  { label: '{UTC}  Costa Rica', tzCode: 'America/Costa_Rica' },
  { label: '{UTC}  El Salvador', tzCode: 'America/El_Salvador' },
  { label: '{UTC}  Galapagos', tzCode: 'Pacific/Galapagos' },
  { label: '{UTC}  Guatemala', tzCode: 'America/Guatemala' },
  { label: '{UTC}  Managua', tzCode: 'America/Managua' },
  { label: '{UTC}  America Cancun', tzCode: 'America/Cancun' },
  { label: '{UTC}  Bogota', tzCode: 'America/Bogota' },
  { label: '{UTC}  Easter Island', tzCode: 'Pacific/Easter' },
  { label: '{UTC}  Eastern Time', tzCode: 'America/New_York' },
  { label: '{UTC}  Eastern Time - Iqaluit', tzCode: 'America/Iqaluit' },
  { label: '{UTC}  Eastern Time - Toronto', tzCode: 'America/Toronto' },
  { label: '{UTC}  Guayaquil', tzCode: 'America/Guayaquil' },
  { label: '{UTC}  Havana', tzCode: 'America/Havana' },
  { label: '{UTC}  Jamaica', tzCode: 'America/Jamaica' },
  { label: '{UTC}  Lima', tzCode: 'America/Lima' },
  { label: '{UTC}  Nassau', tzCode: 'America/Nassau' },
  { label: '{UTC}  Panama', tzCode: 'America/Panama' },
  { label: '{UTC}  Port-au-Prince', tzCode: 'America/Port-au-Prince' },
  { label: '{UTC}  Rio Branco', tzCode: 'America/Rio_Branco' },
  { label: '{UTC}  Atlantic Time - Halifax', tzCode: 'America/Halifax' },
  { label: '{UTC}  Barbados', tzCode: 'America/Barbados' },
  { label: '{UTC}  Bermuda', tzCode: 'Atlantic/Bermuda' },
  { label: '{UTC}  Boa Vista', tzCode: 'America/Boa_Vista' },
  { label: '{UTC}  Caracas', tzCode: 'America/Caracas' },
  { label: '{UTC}  Curacao', tzCode: 'America/Curacao' },
  { label: '{UTC}  Grand Turk', tzCode: 'America/Grand_Turk' },
  { label: '{UTC}  Guyana', tzCode: 'America/Guyana' },
  { label: '{UTC}  La Paz', tzCode: 'America/La_Paz' },
  { label: '{UTC}  Manaus', tzCode: 'America/Manaus' },
  { label: '{UTC}  Martinique', tzCode: 'America/Martinique' },
  { label: '{UTC}  Port of Spain', tzCode: 'America/Port_of_Spain' },
  { label: '{UTC}  Porto Velho', tzCode: 'America/Porto_Velho' },
  { label: '{UTC}  Puerto Rico', tzCode: 'America/Puerto_Rico' },
  { label: '{UTC}  Santo Domingo', tzCode: 'America/Santo_Domingo' },
  { label: '{UTC}  Thule', tzCode: 'America/Thule' },
  {
    label: '{UTC} Newfoundland Time - St. Johns',
    tzCode: 'America/St_Johns'
  },
  { label: '{UTC}  Araguaina', tzCode: 'America/Araguaina' },
  { label: '{UTC}  Asuncion', tzCode: 'America/Asuncion' },
  { label: '{UTC}  Belem', tzCode: 'America/Belem' },
  { label: '{UTC}  Buenos Aires', tzCode: 'America/Argentina/Buenos_Aires' },
  { label: '{UTC}  Campo Grande', tzCode: 'America/Campo_Grande' },
  { label: '{UTC}  Cayenne', tzCode: 'America/Cayenne' },
  { label: '{UTC}  Cuiaba', tzCode: 'America/Cuiaba' },
  { label: '{UTC}  Fortaleza', tzCode: 'America/Fortaleza' },
  { label: '{UTC}  Godthab', tzCode: 'America/Godthab' },
  { label: '{UTC}  Maceio', tzCode: 'America/Maceio' },
  { label: '{UTC}  Miquelon', tzCode: 'America/Miquelon' },
  { label: '{UTC}  Montevideo', tzCode: 'America/Montevideo' },
  { label: '{UTC}  Palmer', tzCode: 'Antarctica/Palmer' },
  { label: '{UTC}  Paramaribo', tzCode: 'America/Paramaribo' },
  { label: '{UTC}  Punta Arenas', tzCode: 'America/Punta_Arenas' },
  { label: '{UTC}  Recife', tzCode: 'America/Recife' },
  { label: '{UTC}  Rothera', tzCode: 'Antarctica/Rothera' },
  { label: '{UTC}  Salvador', tzCode: 'America/Bahia' },
  { label: '{UTC}  Santiago', tzCode: 'America/Santiago' },
  { label: '{UTC}  Stanley', tzCode: 'Atlantic/Stanley' },
  { label: '{UTC}  Noronha', tzCode: 'America/Noronha' },
  { label: '{UTC}  Sao Paulo', tzCode: 'America/Sao_Paulo' },
  { label: '{UTC}  South Georgia', tzCode: 'Atlantic/South_Georgia' },
  { label: '{UTC}  Azores', tzCode: 'Atlantic/Azores' },
  { label: '{UTC}  Cape Verde', tzCode: 'Atlantic/Cape_Verde' },
  { label: '{UTC}  Scoresbysund', tzCode: 'America/Scoresbysund' },
  { label: '{UTC}  Abidjan', tzCode: 'Africa/Abidjan' },
  { label: '{UTC}  Accra', tzCode: 'Africa/Accra' },
  { label: '{UTC}  Bissau', tzCode: 'Africa/Bissau' },
  { label: '{UTC}  Canary Islands', tzCode: 'Atlantic/Canary' },
  { label: '{UTC}  Casablanca', tzCode: 'Africa/Casablanca' },
  { label: '{UTC}  Danmarkshavn', tzCode: 'America/Danmarkshavn' },
  { label: '{UTC}  Dublin', tzCode: 'Europe/Dublin' },
  { label: '{UTC}  El Aaiun', tzCode: 'Africa/El_Aaiun' },
  { label: '{UTC}  Faeroe', tzCode: 'Atlantic/Faroe' },
  { label: '{UTC}  GMT (no daylight saving)', tzCode: 'Etc/GMT' },
  { label: '{UTC}  Lisbon', tzCode: 'Europe/Lisbon' },
  { label: '{UTC}  London', tzCode: 'Europe/London' },
  { label: '{UTC}  Monrovia', tzCode: 'Africa/Monrovia' },
  { label: '{UTC}  Reykjavik', tzCode: 'Atlantic/Reykjavik' },
  { label: '{UTC}  Algiers', tzCode: 'Africa/Algiers' },
  { label: '{UTC}  Amsterdam', tzCode: 'Europe/Amsterdam' },
  { label: '{UTC}  Andorra', tzCode: 'Europe/Andorra' },
  { label: '{UTC}  Berlin', tzCode: 'Europe/Berlin' },
  { label: '{UTC}  Brussels', tzCode: 'Europe/Brussels' },
  { label: '{UTC}  Budapest', tzCode: 'Europe/Budapest' },
  {
    label: '{UTC}  Central European Time - Belgrade',
    tzCode: 'Europe/Belgrade'
  },
  {
    label: '{UTC}  Central European Time - Prague',
    tzCode: 'Europe/Prague'
  },
  { label: '{UTC}  Ceuta', tzCode: 'Africa/Ceuta' },
  { label: '{UTC}  Copenhagen', tzCode: 'Europe/Copenhagen' },
  { label: '{UTC}  Gibraltar', tzCode: 'Europe/Gibraltar' },
  { label: '{UTC}  Lagos', tzCode: 'Africa/Lagos' },
  { label: '{UTC}  Luxembourg', tzCode: 'Europe/Luxembourg' },
  { label: '{UTC}  Madrid', tzCode: 'Europe/Madrid' },
  { label: '{UTC}  Malta', tzCode: 'Europe/Malta' },
  { label: '{UTC}  Monaco', tzCode: 'Europe/Monaco' },
  { label: '{UTC}  Ndjamena', tzCode: 'Africa/Ndjamena' },
  { label: '{UTC}  Oslo', tzCode: 'Europe/Oslo' },
  { label: '{UTC}  Paris', tzCode: 'Europe/Paris' },
  { label: '{UTC}  Rome', tzCode: 'Europe/Rome' },
  { label: '{UTC}  Stockholm', tzCode: 'Europe/Stockholm' },
  { label: '{UTC}  Tirane', tzCode: 'Europe/Tirane' },
  { label: '{UTC}  Tunis', tzCode: 'Africa/Tunis' },
  { label: '{UTC}  Vienna', tzCode: 'Europe/Vienna' },
  { label: '{UTC}  Warsaw', tzCode: 'Europe/Warsaw' },
  { label: '{UTC}  Zurich', tzCode: 'Europe/Zurich' },
  { label: '{UTC}  Amman', tzCode: 'Asia/Amman' },
  { label: '{UTC}  Athens', tzCode: 'Europe/Athens' },
  { label: '{UTC}  Beirut', tzCode: 'Asia/Beirut' },
  { label: '{UTC}  Bucharest', tzCode: 'Europe/Bucharest' },
  { label: '{UTC}  Cairo', tzCode: 'Africa/Cairo' },
  { label: '{UTC}  Chisinau', tzCode: 'Europe/Chisinau' },
  { label: '{UTC}  Damascus', tzCode: 'Asia/Damascus' },
  { label: '{UTC}  Gaza', tzCode: 'Asia/Gaza' },
  { label: '{UTC}  Helsinki', tzCode: 'Europe/Helsinki' },
  { label: '{UTC}  Jerusalem', tzCode: 'Asia/Jerusalem' },
  { label: '{UTC}  Johannesburg', tzCode: 'Africa/Johannesburg' },
  { label: '{UTC}  Khartoum', tzCode: 'Africa/Khartoum' },
  { label: '{UTC}  Kiev', tzCode: 'Europe/Kiev' },
  { label: '{UTC}  Maputo', tzCode: 'Africa/Maputo' },
  { label: '{UTC}  Moscow-01 - Kaliningrad', tzCode: 'Europe/Kaliningrad' },
  { label: '{UTC}  Nicosia', tzCode: 'Asia/Nicosia' },
  { label: '{UTC}  Riga', tzCode: 'Europe/Riga' },
  { label: '{UTC}  Sofia', tzCode: 'Europe/Sofia' },
  { label: '{UTC}  Tallinn', tzCode: 'Europe/Tallinn' },
  { label: '{UTC}  Tripoli', tzCode: 'Africa/Tripoli' },
  { label: '{UTC}  Vilnius', tzCode: 'Europe/Vilnius' },
  { label: '{UTC}  Windhoek', tzCode: 'Africa/Windhoek' },
  { label: '{UTC}  Baghdad', tzCode: 'Asia/Baghdad' },
  { label: '{UTC}  Istanbul', tzCode: 'Europe/Istanbul' },
  { label: '{UTC}  Minsk', tzCode: 'Europe/Minsk' },
  { label: '{UTC}  Moscow+00 - Moscow', tzCode: 'Europe/Moscow' },
  { label: '{UTC}  Nairobi', tzCode: 'Africa/Nairobi' },
  { label: '{UTC}  Qatar', tzCode: 'Asia/Qatar' },
  { label: '{UTC}  Riyadh', tzCode: 'Asia/Riyadh' },
  { label: '{UTC}  Syowa', tzCode: 'Antarctica/Syowa' },
  { label: '{UTC} Tehran', tzCode: 'Asia/Tehran' },
  { label: '{UTC}  Baku', tzCode: 'Asia/Baku' },
  { label: '{UTC}  Dubai', tzCode: 'Asia/Dubai' },
  { label: '{UTC}  Mahe', tzCode: 'Indian/Mahe' },
  { label: '{UTC}  Mauritius', tzCode: 'Indian/Mauritius' },
  { label: '{UTC}  Moscow+01 - Samara', tzCode: 'Europe/Samara' },
  { label: '{UTC}  Reunion', tzCode: 'Indian/Reunion' },
  { label: '{UTC}  Tbilisi', tzCode: 'Asia/Tbilisi' },
  { label: '{UTC}  Yerevan', tzCode: 'Asia/Yerevan' },
  { label: '{UTC} Kabul', tzCode: 'Asia/Kabul' },
  { label: '{UTC}  Aqtau', tzCode: 'Asia/Aqtau' },
  { label: '{UTC}  Aqtobe', tzCode: 'Asia/Aqtobe' },
  { label: '{UTC}  Ashgabat', tzCode: 'Asia/Ashgabat' },
  { label: '{UTC}  Dushanbe', tzCode: 'Asia/Dushanbe' },
  { label: '{UTC}  Karachi', tzCode: 'Asia/Karachi' },
  { label: '{UTC}  Kerguelen', tzCode: 'Indian/Kerguelen' },
  { label: '{UTC}  Maldives', tzCode: 'Indian/Maldives' },
  { label: '{UTC}  Mawson', tzCode: 'Antarctica/Mawson' },
  {
    label: '{UTC}  Moscow+02 - Yekaterinburg',
    tzCode: 'Asia/Yekaterinburg'
  },
  { label: '{UTC}  Tashkent', tzCode: 'Asia/Tashkent' },
  { label: '{UTC} Colombo', tzCode: 'Asia/Colombo' },
  { label: '{UTC} India Standard Time', tzCode: 'Asia/Kolkata' },
  { label: '{UTC} Kathmandu', tzCode: 'Asia/Kathmandu' },
  { label: '{UTC}  Almaty', tzCode: 'Asia/Almaty' },
  { label: '{UTC}  Bishkek', tzCode: 'Asia/Bishkek' },
  { label: '{UTC}  Chagos', tzCode: 'Indian/Chagos' },
  { label: '{UTC}  Dhaka', tzCode: 'Asia/Dhaka' },
  { label: '{UTC}  Moscow+03 - Omsk', tzCode: 'Asia/Omsk' },
  { label: '{UTC}  Thimphu', tzCode: 'Asia/Thimphu' },
  { label: '{UTC}  Vostok', tzCode: 'Antarctica/Vostok' },
  { label: '{UTC} Cocos', tzCode: 'Indian/Cocos' },
  { label: '{UTC} Rangoon', tzCode: 'Asia/Yangon' },
  { label: '{UTC}  Bangkok', tzCode: 'Asia/Bangkok' },
  { label: '{UTC}  Christmas', tzCode: 'Indian/Christmas' },
  { label: '{UTC}  Davis', tzCode: 'Antarctica/Davis' },
  { label: '{UTC}  Hanoi', tzCode: 'Asia/Saigon' },
  { label: '{UTC}  Hovd', tzCode: 'Asia/Hovd' },
  { label: '{UTC}  Jakarta', tzCode: 'Asia/Jakarta' },
  { label: '{UTC}  Moscow+04 - Krasnoyarsk', tzCode: 'Asia/Krasnoyarsk' },
  { label: '{UTC}  Brunei', tzCode: 'Asia/Brunei' },
  { label: '{UTC}  China Time - Beijing', tzCode: 'Asia/Shanghai' },
  { label: '{UTC}  Choibalsan', tzCode: 'Asia/Choibalsan' },
  { label: '{UTC}  Hong Kong', tzCode: 'Asia/Hong_Kong' },
  { label: '{UTC}  Kuala Lumpur', tzCode: 'Asia/Kuala_Lumpur' },
  { label: '{UTC}  Macau', tzCode: 'Asia/Macau' },
  { label: '{UTC}  Makassar', tzCode: 'Asia/Makassar' },
  { label: '{UTC}  Manila', tzCode: 'Asia/Manila' },
  { label: '{UTC}  Moscow+05 - Irkutsk', tzCode: 'Asia/Irkutsk' },
  { label: '{UTC}  Singapore', tzCode: 'Asia/Singapore' },
  { label: '{UTC}  Taipei', tzCode: 'Asia/Taipei' },
  { label: '{UTC}  Ulaanbaatar', tzCode: 'Asia/Ulaanbaatar' },
  { label: '{UTC}  Western Time - Perth', tzCode: 'Australia/Perth' },
  { label: '{UTC}  Pyongyang', tzCode: 'Asia/Pyongyang' },
  { label: '{UTC}  Dili', tzCode: 'Asia/Dili' },
  { label: '{UTC}  Jayapura', tzCode: 'Asia/Jayapura' },
  { label: '{UTC}  Moscow+06 - Yakutsk', tzCode: 'Asia/Yakutsk' },
  { label: '{UTC}  Palau', tzCode: 'Pacific/Palau' },
  { label: '{UTC}  Seoul', tzCode: 'Asia/Seoul' },
  { label: '{UTC}  Tokyo', tzCode: 'Asia/Tokyo' },
  { label: '{UTC} Central Time - Darwin', tzCode: 'Australia/Darwin' },
  { label: "{UTC}  Dumont D'Urville", tzCode: 'Antarctica/DumontDUrville' },
  { label: '{UTC}  Eastern Time - Brisbane', tzCode: 'Australia/Brisbane' },
  { label: '{UTC}  Guam', tzCode: 'Pacific/Guam' },
  { label: '{UTC}  Moscow+07 - Vladivostok', tzCode: 'Asia/Vladivostok' },
  { label: '{UTC}  Port Moresby', tzCode: 'Pacific/Port_Moresby' },
  { label: '{UTC}  Truk', tzCode: 'Pacific/Chuuk' },
  { label: '{UTC} Central Time - Adelaide', tzCode: 'Australia/Adelaide' },
  { label: '{UTC}  Casey', tzCode: 'Antarctica/Casey' },
  { label: '{UTC}  Eastern Time - Hobart', tzCode: 'Australia/Hobart' },
  {
    label: '{UTC}  Eastern Time - Melbourne, Sydney',
    tzCode: 'Australia/Sydney'
  },
  { label: '{UTC}  Efate', tzCode: 'Pacific/Efate' },
  { label: '{UTC}  Guadalcanal', tzCode: 'Pacific/Guadalcanal' },
  { label: '{UTC}  Kosrae', tzCode: 'Pacific/Kosrae' },
  { label: '{UTC}  Moscow+08 - Magadan', tzCode: 'Asia/Magadan' },
  { label: '{UTC}  Norfolk', tzCode: 'Pacific/Norfolk' },
  { label: '{UTC}  Noumea', tzCode: 'Pacific/Noumea' },
  { label: '{UTC}  Ponape', tzCode: 'Pacific/Pohnpei' },
  { label: '{UTC}  Funafuti', tzCode: 'Pacific/Funafuti' },
  { label: '{UTC}  Kwajalein', tzCode: 'Pacific/Kwajalein' },
  { label: '{UTC}  Majuro', tzCode: 'Pacific/Majuro' },
  {
    label: '{UTC} Moscow+09 - Petropavlovsk-Kamchatskiy',
    tzCode: 'Asia/Kamchatka'
  },
  { label: '{UTC}  Nauru', tzCode: 'Pacific/Nauru' },
  { label: '{UTC}  Tarawa', tzCode: 'Pacific/Tarawa' },
  { label: '{UTC}  Wake', tzCode: 'Pacific/Wake' },
  { label: '{UTC}  Wallis', tzCode: 'Pacific/Wallis' },
  { label: '{UTC}  Auckland', tzCode: 'Pacific/Auckland' },
  { label: '{UTC}  Enderbury', tzCode: 'Pacific/Enderbury' },
  { label: '{UTC}  Fakaofo', tzCode: 'Pacific/Fakaofo' },
  { label: '{UTC}  Fiji', tzCode: 'Pacific/Fiji' },
  { label: '{UTC}  Tongatapu', tzCode: 'Pacific/Tongatapu' },
  { label: '{UTC}  Apia', tzCode: 'Pacific/Apia' },
  { label: '{UTC}  Kiritimati', tzCode: 'Pacific/Kiritimati' }
].map(({ tzCode, label }) => {
  const offset = moment().tz(tzCode).format('Z');

  return {
    tzCode,
    label: label.replace('{UTC}', `(UTC ${offset})`),
    offset
  };
});
