import * as Types from './types';

const initialState = {
  loading: false,
  error: null,
  isLoggedIn: false,
  token: localStorage.getItem('token')
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.LOGIN:
    case Types.REFRESH:
      return {
        loading: false,
        error: null,
        isLoggedIn: true,
        token: action.payload
      };
    case Types.LOGOUT:
      return {
        error: null,
        isLoggedIn: false,
        token: undefined,
        loading: false
      };
    default:
      return state;
  }
};
