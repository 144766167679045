import i18next from 'i18next';

/**
 * i18next uses a "PluralResolver" internally to resolve plural keys, e.g. i18next.t('item', { count: 3 }).
 * In order to resolve them, i18next uses a dictionary of locales for each language available in i18next
 * with a function associated with it to resolve the plural forms. Each locale key and its respective
 * resolver function is called a "rule". Since i18next uses hyphens for locales (e.g. en-GB) it is not able
 * to resolve plural keys for our locales using underscores (e.g. en_GB). Hence, the PluralResolver
 * falls back to the key's singular form of the current language and i18next logs a warning. In order to fix
 * this problem, we add rules for the underscore locales by applying the same resolver function as the
 * hyphened locale.
 *
 * This function allows you to pass an additional locale in case you need an extra language to add a rule
 * for that is not present in i18next.languages. This is for instance needed for the "Academy", as they
 * allow the user to display content in a language different from the application's language.
 *
 * @param additionalLang An additional language locale you want to add a plural resolver rule for.
 */
export const addPluralResolvers = (additionalLang?: string) => {
  const languages = [...i18next.languages];

  if (additionalLang) {
    languages.push(additionalLang);
  }

  const uniqueUnderscoreLanguages = languages.filter(
    (lang, index) =>
      lang.indexOf('_') >= 0 && // only get the ones with an underscore
      languages.indexOf(lang) === index // only get unique ones
  );

  uniqueUnderscoreLanguages.forEach(lang => {
    const langWithHyphen = lang.replace('_', '-');
    const langWithUnderscore = lang.replace('-', '_');

    const ruleForLangWithHyphen = i18next.services.pluralResolver.getRule(langWithHyphen);
    const ruleForLangWithUnderscore = i18next.services.pluralResolver.getRule(langWithUnderscore);

    // we only add a rule if necessary
    if (!ruleForLangWithUnderscore && ruleForLangWithHyphen) {
      i18next.services.pluralResolver.addRule(langWithUnderscore, ruleForLangWithHyphen);
    }
  });
};
