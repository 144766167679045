import * as Types from './types';

const initialState = {
  companyBriefingAvailability: null,
  coacheeProfileBriefingAvailability: null,
  error: null,
  loading: false
};

export const companyBriefingReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.COMPANY_BRIEFING_LOADING:
      return {
        ...state,
        loading: true
      };
    case Types.COMPANY_BRIEFING_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case Types.GET_COMPANY_BRIEFING_AVAILABILITY_SUCCESS:
      return {
        ...state,
        companyBriefingAvailability: action.payload,
        error: null,
        loading: false
      };
    case Types.GET_COACHEE_PROFILE_COMPANY_BRIEFING:
      return {
        ...state,
        coacheeProfileBriefingAvailability: action.payload,
        error: null,
        loading: false
      };
    default:
      return state;
  }
};
