// mapped browser locale to fetch corresponding s3 files
export const mappedLocales = {
  tr: 'tr_TR',
  es: 'es_ES',
  ru: 'ru_RU',
  ro: 'ro_RO',
  de: 'de_DE',
  en: 'en_GB',
  fr: 'fr_FR',
  'fr-FR': 'fr_FR',
  it: 'it_IT',
  nl: 'nl_NL',
  'pt-PT': 'pt_PT',
  'pt-BR': 'pt_BR',
  ja: 'ja_JP',
  'zh-CN': 'zh_CN',
  'zh-TW': 'zh_TW',
  he: 'he_HE',
  ko: 'ko_KR',
  hu: 'hu_HU',
  pl: 'pl_PL',
  ar: 'ar_SA'
};
