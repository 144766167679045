import * as Types from './types';
import { ProgramState } from '../types';
import { AnyAction } from 'redux';

const initialState = {
  loading: false,
  error: null,
  program: null
};

export const programReducer = (state: ProgramState = initialState, action: AnyAction) => {
  switch (action.type) {
    case Types.PROGRAM_GET: {
      return {
        ...state,
        loading: false,
        error: null,
        program: action.payload
      };
    }
    case Types.PROGRAM_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case Types.PROGRAM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    default:
      return state;
  }
};
